import { CMSLink } from '../types';

export const getText = (content: any, propName: string): string => {
    return content
        && content[propName]
        && content[propName].Text;
};

export const getLinkObj = (content: any, propName: string): CMSLink  => {
    return content
        && content[propName];
};
