import { Address } from "../types";

export const parseJWT = (jwt: string | null) => {
    if (jwt) {
        const b64URL = jwt.split('.')[1];
        const b64 = b64URL.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(b64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
};

export const isEnhancedLogin = ():boolean => {
    const parsedJWT = parseJWT(sessionStorage.getItem('vor-jwt'));

    return parsedJWT && parsedJWT.enhancedLogin === 'true' ? true : false;
}

export const parseCurrency = (currency?: number, noValReturn: string = ''): string => {
    if (currency) {
        const price = currency.toFixed(2);
        return `$${price}`;
    }
    return noValReturn;
}
export const validateLegalAddressForm = (values: any) => {
    const errors: any = {};
    // tslint:disable-next-line
    const POBoxRegex = /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+) /i;

    if (POBoxRegex.test(values.lineOne)) {
        errors.address = 'A PO Box cannot be used as a legal address.';
    }

    if (!values.lineOne || values.lineOne === '') {
        errors.address = 'Address Required.';
    }

    if (!values.city || values.city === '') {
        errors.city = 'City Required.';
    }

    if (!values.state || values.state === '') {
        errors.state = 'State Required.';
    }

    if (!values.zip || values.zip === '') {
        errors.zip = 'Zip Required.';
    }

    if (values.lineOne && values.lineOne.length > 20) {
        errors.address = 'Address length cannot exceed 20 characters.';
    }

    if (values.city && values.city.length > 16) {
        errors.city = 'City length cannot exceed 16 characters.';
    }

    if (values.zip && values.zip.length > 11) {
        errors.zip = 'Zip length cannot exceed 11 characters.';
    }
    return errors;
};

export const formatHeightFeet = (value: string): string => {
    if (value) {
        if (!/^\d+$/.test(value)) {
            return '';
        } else if (Number(value) > 9) {
            return '9';
        } else if (Number(value) < 1) {
            return '1';
        }
    }
    return value;
}

export const formatHeightInches = (value: string): string => {
    if (value) {
        if (!/^\d+$/.test(value)) {
            return '';
        } else if (Number(value) > 11) {
            return '11';
        } else if (Number(value) < 0) {
            return '0';
        }
    }
    return value;
}

export const formatWeight = (weight: any) => {
    if (!weight) {
        return weight;
    }
    if (weight) {
        if (!/^\d+$/.test(weight)) {
            return '';
        } else if (Number(weight) > 999) {
            return '999';
        } else if (Number(weight) < 1) {
            return '1';
        } else {
            return weight;
        }
    }
};

export const getCleanAddress = (address: Address): Address => {
    const cleanAddress = address;
    cleanAddress.country = cleanAddress.country === null ? "" : cleanAddress.country;
    cleanAddress.lineTwo = cleanAddress.lineTwo === null ? "" : cleanAddress.lineTwo;
    cleanAddress.lineThree = cleanAddress.lineThree === null ? "" : cleanAddress.lineThree;
    return cleanAddress
}
