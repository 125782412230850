import React from 'react'
import { getText } from '../../../utils/ContentHelpers';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';

import SelectableTr from './SelectableTr';
import { parseCurrency } from '../../../utils/UtilityFunctions';

const SelectTable = selectTableHOC(ReactTable);


interface Props {
    data: any
    content: any,
    selectedRowId?: number | string
    onSelect: (rowId: number) => void
}

const SelectionTable = ({
    data,
    content,
    selectedRowId,
    onSelect
}: Props) => {

    const formatDateCells = (row: any) => {
        const muted = row && (
                row.original.renewal4YearPrice
                || row.original.renewal2YearPrice
                || row.original.replacementPrice
                || row.original.restrictedPrice
            ) ? '' : 'light-grey-text';

        if (row.value) {
            const dateString = row.value.split('T')[0];
            const dateArray = dateString.split("-");
            const formattedDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
            return (
                <div className={muted}>
                    {formattedDate}
                </div>
            );
        }
        return (
            <div className={muted}>
                {'N/A'}
            </div>
        );
    };

    const formatPriceCells = (row: any) => {
        const muted = row && (
                row.original.renewal4YearPrice
                || row.original.renewal2YearPrice
                || row.original.replacementPrice
                || row.original.restrictedPrice
            ) ? '' : 'light-grey-text';

        if (row.value) {
            return (
                <div className={muted}>
                    {parseCurrency(row.value, 'N/A')}
                </div>
            );
        }
        return (
            <div className={muted}>
                {'N/A'}
            </div>
        );
    };

    const columns = [
        {
            Header: getText(content, "TableHeadingColType"),
            accessor: "contextData.licensetype",
            Cell: (rowInfo: any) => {
                const muted = rowInfo && (
                        rowInfo.original.renewal4YearPrice
                        || rowInfo.original.renewal2YearPrice
                        || rowInfo.original.replacementPrice
                        || rowInfo.original.restrictedPrice
                    ) ? '' : 'light-grey-text';
                return (
                    <div className={`${muted}`}>
                        {rowInfo.original.contextData.licensetype}
                    </div>
                );
            }
        },
        {
            Header: getText(content, "TableHeadingColIssueDate"),
            accessor: "issueDate",
            Cell: formatDateCells
        },
        {
            Header: getText(content, "TableHeadingColExpDate"),
            accessor: "expirationDate",
            Cell: formatDateCells
        },
        {
            Header: getText(content, "TableHeadingColStatus"),
            accessor: "contextData.status",
            Cell: (rowInfo: any) => {
                const muted = rowInfo && (
                        rowInfo.original.renewal4YearPrice
                        || rowInfo.original.renewal2YearPrice
                        || rowInfo.original.replacementPrice
                        || rowInfo.original.restrictedPrice
                    ) ? '' : 'light-grey-text';
                return (
                    <div className={`${muted}`}>
                        {rowInfo.original.contextData.status}
                    </div>
                );
            }
        },
        {
            Header:  getText(content, "TableHeadingColRenewal4Year"),
            accessor: "renewal4YearPrice",
            Cell: formatPriceCells
        },
        {
            Header:  getText(content, "TableHeadingColRenewal2Year"),
            accessor: "renewal2YearPrice",
            Cell: formatPriceCells
        },
        {
            Header: getText(content, "TableHeadingColReplace"),
            accessor: "replacementPrice",
            Cell: formatPriceCells
        },
        {
            Header: getText(content, "TableHeadingColRestricted"),
            accessor: "restrictedPrice",
            Cell: formatPriceCells
        }
    ]

    const toggleSelection = (key: any, shift: any, row: any) => {
        if (isSelected(row.id)) {
            onSelect(0);
        } else {
            onSelect(row.id);
        }
    }

    const isSelected = (key: any) => {
        return selectedRowId === key;
    }

    const getSelectableTrProps = (DataGridProps: any, rowData: any) => {
        if (
            rowData
                && (
                    rowData.original.renewal4YearPrice
                    || rowData.original.renewal2YearPrice
                    || rowData.original.replacementPrice
                    || rowData.original.restrictedPrice
                )
            ) {
            return {
                id: rowData.original.id,
                selected: isSelected(rowData.original.id),
                selectRow: toggleSelection,
                rowData: rowData
            };
        }
        return {};
    };
    const getSelectableInput = (DataGridProps: any) => {
        if (
            DataGridProps.row
            && (
                DataGridProps.row.renewal4YearPrice
                || DataGridProps.row.renewal2YearPrice
                || DataGridProps.row.replacementPrice
                || DataGridProps.row.restrictedPrice
            )
        ) {
            return (
                <div>
                    <input
                        type='radio'
                        // onClick={toggleSelection},
                        checked={isSelected(DataGridProps.row.id)}
                        // row: rowData,
                        // id: rowData.original.id,
                        // // selected: isSelected(rowData.original.id),
                        // // selectRow: toggleSelection,
                    />
                </div>
            );
        }
        return (
            <React.Fragment>
            </React.Fragment>
        );
    };

    return (
        <SelectTable
            className="-striped -highlight"
            showPagination={false}
            sortable={false}
            pageSize={data.length}
            manual
            keyField="id"
            columns={columns}
            data={data}
            selectType={'radio'}
            selectAll={false}
            toggleSelection={toggleSelection}
            isSelected={isSelected}
            TrComponent={SelectableTr}
            SelectInputComponent={getSelectableInput}
            getTrProps={getSelectableTrProps}
        />
    );
};

export default SelectionTable;
