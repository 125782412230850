import * as React from 'react';

class SelectableTr extends React.Component<any, any> {

    onSingleClick = () => {
        if (this.props.selectRow) {
            this.props.selectRow(null, null, this.props);
        }
    }

    render() {
        const style = { backgroundColor: this.props.selected ? 'rgba(0, 187, 238, 0.25)' : undefined };
        return (
            <div
                className={`rt-tr ${this.props.className}`}
                style={style}
                onClick={this.onSingleClick}
            >
                {this.props.children}
            </div>
        );
    }
}

export default SelectableTr;
