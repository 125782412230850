import React, {useMemo} from "react";
import {Button} from "reactstrap";
import {Transaction} from "../requestActions/transactionActions";
import {openPrintReceiptWindow, ReceiptModel} from "../utils/ReceiptGenerator";

interface RePrintReceiptButtonProps {
    transaction: Transaction
    dateBecameNotInflight: string
}

export const RePrintReceiptButton: React.FC<RePrintReceiptButtonProps> = ({ transaction, dateBecameNotInflight }) => {

    const receivedPaymentTransaction = useMemo<boolean>(
        () => !!Object.keys(transaction)?.length,
        [transaction]
    );

    /**
     * Returns True if we are within 5 days of when the record is no longer in flight
     */
    const isInReprintWindow = useMemo<boolean>(() => {
        if (!dateBecameNotInflight) { return true; }

        const inFlightEnd = new Date(dateBecameNotInflight).getTime();
        const now = new Date().getTime();
        const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;

        return (now - inFlightEnd) <= fiveDaysInMilliseconds;
    }, [dateBecameNotInflight]);


    if (!receivedPaymentTransaction || !isInReprintWindow) {
        return <Button className="general-btn" disabled={true} id="RePrintButton">N/A</Button>;
    }

    const boolToYesOrNo = (bool: boolean): "YES"|"NO" => bool ? "YES" : "NO";

    const transactionToReceiptModel = (transaction: Transaction): ReceiptModel => ({
        firstName: transaction.firstname,
        lastName: transaction.lastname,
        middleInitial: transaction.middleinitial,
        suffix: transaction.suffix,
        credentialType: transaction.credentialtype,
        restrictions: transaction.restrictions,
        endorsements: transaction.endorsements,
        class: transaction.class,
        organDonor: boolToYesOrNo(transaction.organdonor),
        veteran: boolToYesOrNo(transaction.veteran),
        paymentType: transaction.paymenttype,
        lastFourCreditCard: transaction.lastforcreditcard,
        cardholderName: transaction.cardholdername,
        confirmationNumber: transaction.confirmationnumber,
        dateOfTransaction: transaction.dateoftransaction,
        orderTotal: transaction.ordertotal,
        serviceType: transaction.servicetype
    });

    const onReprintClick = () => {
        openPrintReceiptWindow(transactionToReceiptModel(transaction));
    }

    return <Button onClick={onReprintClick} className="general-btn">Re-Print</Button>;
}
