import React, {useState} from 'react';
import HelpIcon from './HelpIcon';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {getText} from '../utils/ContentHelpers'

interface Props {
    headerTitle: any
}

const HelpModalWrapper: React.FC<Props> = (props) => {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const contentError = () => {
        if (!props.headerTitle) {
            return true;
        }
        return false;
    }

    const renderHeader = () => {
        if (contentError()) {
            return 'Error:'
        } else {
            return getText(props.headerTitle, 'HeaderTitle');
        }
    }

    const renderBody = () => {
        if (contentError()) {
            return (<p>Error retrieving content. Please refresh and try again.</p>);
        } else {
            return props.children;
        }
    }

    return (
        <React.Fragment>
            <HelpIcon
                onClick={toggle}
            />
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    { renderHeader() }
                </ModalHeader>
                <ModalBody>
                    { renderBody() }
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default HelpModalWrapper;
