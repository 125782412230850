import React from 'react';
import {
    useLocation
} from "react-router-dom";

const NotFound404 = () => {
    let location = useLocation();

    return (
        <div>
            <h3>
                404 Page Not Found for <code>{location.pathname}</code>
            </h3>
        </div>
    );
};

export default NotFound404;
