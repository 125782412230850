import React from 'react';
import {Row, Col, Button} from 'reactstrap';
import { getText, getLinkObj } from '../../utils/ContentHelpers';
import LogoutBtn from '../../components/LogoutBtn';
import {Redirect} from "react-router-dom";

interface Props {
    content?: any
}

class NoCredentials extends React.Component <Props> {
    state = {
        redirectUrl: ""
    };

    render() {
        const {
            content
        } = this.props;
        const link = getLinkObj(content, 'NoCredentialWebAddress');
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h1 className="title">
                            {getText(content, 'NoCredentialTitle')}
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="sub-title">
                            {getText(content, 'NoCredentialSubtitle')}
                        </h4>
                    </Col>
                </Row>
                <Row className="no-cred-message-container">
                    <Col>
                        <h4 className="sub-title">
                            {getText(content, 'NoCredentialMessage')}
                        </h4>
                    </Col>
                </Row>
                <Row className="no-cred-contact-message">
                    <Col>
                        <div>
                            {getText(content, 'NoCredentialContactMessage')}
                        </div>
                    </Col>
                </Row>
                <Row className="no-cred-cancel">
                    <Col sm={3}>
                        <LogoutBtn
                            text={getText(content, 'NoCredentialCancelButtonText')}
                            className="cancel-btn"
                        />
                    </Col>
                    <Col sm={4}>
                        <Button
                            className="general-btn"
                            onClick={() => this.setState({ redirectUrl: '/transaction-history' })}
                        >
                            Transaction History
                        </Button>
                    </Col>
                </Row>
                <Row className="no-cred-contact-us">
                    <Col>
                        <div>
                            {getText(content, 'NoCredentialWebsiteMessage')}
                            <a href={link.Url}>{link.Text || link.Url}</a>
                        </div>
                    </Col>
                </Row>
                {this.state.redirectUrl ? <Redirect to={this.state.redirectUrl}/> : <></>}
            </React.Fragment>
        );
    }
}

export default NoCredentials;
