import {getItemAction, postItemAction} from './baseActions';
import { TransactionModel } from '../types';

export const createPaymentTransaction = (transactionModel: TransactionModel) => {

    return postItemAction({
        url: '/cms/api/online-renewal/transactions',
        bodyObj: transactionModel
    });
}

export interface Transaction {
    "firstname": string
    "lastname": string
    "middleinitial": string
    "suffix": string
    "credentialtype": string
    "restrictions": string
    "endorsements": string
    "class": string
    "organdonor": boolean
    "veteran": boolean
    "paymenttype": string
    "lastforcreditcard": string
    "cardholdername": string
    "confirmationnumber": string
    "dateoftransaction": string
    "servicetype": string
    "pricingname": string
    "ordertotal": number
}
export const getTransactionDetails = (transactionId: string) => {
    return getItemAction({
        url: `/cms/api/online-renewal/transactions/${transactionId}`
    }) as Promise<{ data: Transaction }>;
}

export const completePaymentTransaction = (transactionId: string, confId: string) => {
    return postItemAction({
        url: `/cms/api/online-renewal/transactions/${transactionId}/complete`,
        bodyObj: {
            transactionConfirmationNumber: confId
        }
    });
}

export type TransactionHistoryItem = {
    transactionId: string | null
    identityId: string | null
    credentialId: string | null
    credentialRequestId: string | null
    dateBecameNotInflight: string | null
    fields: Transaction
}
export const getPaymentTransactionHistory = () => {
    return getItemAction({
        url: `cms/api/online-renewal/transaction-history`
    }) as Promise<{ data: TransactionHistoryItem[] }>;
};

