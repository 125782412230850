import { getItemAction } from './baseActions';

const addLangaugeToRoute = (route: string, language: string = "") => {
    if (language && language !== 'en-US') {
        route += `-${language}`;
    }
    return route;
}

export const getSharedContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/shared-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};

export const getAuthContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/auth-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};
export const getAuthHelpContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/auth-content-help', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};

export const getCredSelectionContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/credential-selection-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};

export const getCredSelectionHelpContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/credential-selection-help-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
}
;
export const getPaymentConfirmationContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/payment-confirmation-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};

export const getCredEditContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/credentials-edits-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};

export const getReviewEditsContent = (language: string = "") => {
    return getItemAction({
        url: addLangaugeToRoute('/cms/api/content-items/review-edits-content', language),
        customRequestConfig:{
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
};
