import React from 'react';
import TopHeader from './Headers/TopHeader';
import { Row, Col } from 'reactstrap';

interface State {
    hasError: boolean
}

class ErrorBoundry extends React.Component <any, State> {
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        console.error(error);
        console.error(info);
    }

    render() {
        // waiting for a better design from VT
        // But this is better than a whitescreen for users.
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <header>
                        <TopHeader/>
                    </header>
                    <Row className="mt-5">
                        <Col sm={2}></Col>
                        <Col>
                            <h1 className="greyed-text">
                                An unexpected error has occurred.
                            </h1>
                            <h2 className="greyed-text">
                                Please refresh and try again.
                            </h2>
                            <div className="mt-4 auth-text-fields auth-text-greyed">
                                <p>
                                    If this problem persists please contact us at:&nbsp;
                                    <a href="https://dmv.vermont.gov/contact">https://dmv.vermont.gov/contact</a>
                                </p>
                            </div>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </React.Fragment>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundry;
