import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Header from './components/Headers/MainHeader';
import {
    AuthFromValues, AuthResponseValues
} from './types';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {
    AuthenticatePage
} from './views';
import AuthenticatedApp from './AuthenticatedApp';
import { getText } from './utils/ContentHelpers';
import {
    authenticateAction,
    getReCaptchaSiteKey
} from './requestActions/authenticaitonActions';
import {
    getAuthContent,
    getSharedContent
} from './requestActions/contentActions';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './styles/App.scss';

class App extends React.Component {
    state = {
        authPageText: {
            AuthPage: {}
        } as any,
        sharedContentText: {
            SharedContentItems: {}
        },
        siteKey: "",
        // isAuthenticated: false,
        loadingContent: true,
        jwt: '',
        language: "",
        authLoading: false,
        authErrors: [] as Array<string>,
        displayAuthErrors: false,
        reauthorized: false
    };

    componentDidMount() {
        this.getReCaptchaSiteKey();
        this.getAuthPageContent(this.determineLangauge());
        this.isAuthenticated();
    }

    determineLangauge() {
        const sessionLanguage = this.getSessionLanguage();
        let browserLangage = '';
        if (
            window.navigator.language.slice(0, 2) !== 'en'
            && window.navigator.language.slice(0, 2) === 'es'
        ) {
            // we only support es-MX currently so all es langages becomes es-MX
            browserLangage = 'es-MX';
        }
        this.setState({language: sessionLanguage || browserLangage});
        return sessionLanguage || browserLangage;
    }

    getSessionLanguage = () => {
        const language = sessionStorage.getItem('vor-localization');
        if (language) {
            return language;
        }
    }

    getReCaptchaSiteKey = () => {
        getReCaptchaSiteKey()
            .then((response: any) => {
                this.setState({siteKey: response.data});
            })
            .catch((error: any) => {

            })
    }

    getAuthPageContent = (localization: string = "") => {
        const promiseArray = [
            getSharedContent(localization),
            getAuthContent(localization)
        ];
        this.setState({
            loadingContent: true
        });
        Promise.all(promiseArray)
            .then(([sharedContent, authContent]) => {
                this.setState({
                    authPageText: authContent.data,
                    sharedContentText: sharedContent.data,
                    loadingContent: false
                });
            })
            .catch((error: any) => {
                console.error(error);
                // Should probably display an error of some kind to the user
                this.setState({
                    loadingContent: false
                });
            });
    }

    isAuthenticated = () => {
        const jwt = sessionStorage.getItem('vor-jwt');
        if (jwt) {
            this.setState({
                jwt: jwt
            });
        } else {
            this.setState({
                jwt: ''
            });
        }
    }

    checkAuthenticationResponse = (authResponse: AuthResponseValues) => {
        if (authResponse.success && authResponse.token) {
            sessionStorage.setItem('vor-jwt', authResponse.token);
            this.setState({
                jwt: authResponse.token
            });

            return true;
        }

        return false;
    }

    authConfirm = (
        saveAuthResults: ({authLoading, authErrors, displayAuthErrors}: {authLoading: boolean, displayAuthErrors: boolean, authErrors: any[]}) => void,
        reAuth?: boolean
    ) => (authFormValues: AuthFromValues, reCaptchaToken: string) => {
        // send info to server here
        const { dob } = authFormValues;
        const dobString = dob ?
            `${dob.getFullYear()}-`
            + `${('0' + (dob.getMonth()+1)).slice(-2)}-`
            + ('0' + dob.getDate()).slice(-2) : '';

        return authenticateAction({
            LastName: authFormValues.lastName,
            DateOfBirth: dobString,
            PID: authFormValues.idNum,
            SSN: authFormValues.ssn || undefined,
            PIN: authFormValues.pin || undefined,
            DocDiscriminator: authFormValues.ddn || undefined,
            Captcha: reCaptchaToken
        })
            .then((response: any) => {
                saveAuthResults({
                    authLoading: false,
                    displayAuthErrors: false,
                    authErrors: []
                });
                const authSuccessful = this.checkAuthenticationResponse(response.data);

                if (reAuth) {
                    this.setState({
                        reauthorized: authSuccessful
                    });
                }
                return true;
            })
            .catch((error: any) => {
                console.error(error);
                const unknownError = ['An unknown error has occurred. Please refresh and try again.'];

                // if the error is formatted like > {PID: ["One or more Identity Confirmation fields do not match."]}
                const flatten = (obj: any): any => {
                    const result = [];
                    for (const prop in obj) {
                        const value = obj[prop];
                        if (typeof value === 'object') {
                            result.push(flatten(value));
                        } else {
                            result.push(value);
                        }
                    }
                    return result;
                }

                if (!error?.response?.data || error.response.data.success) {
                    saveAuthResults({
                        authLoading: false,
                        displayAuthErrors: true,
                        authErrors: unknownError
                    });
                    return false;
                }

                if (error?.response?.data?.errors?.length) {
                    saveAuthResults({
                        authLoading: false,
                        displayAuthErrors: true,
                        authErrors: error.response.data.errors
                    });
                    return false;
                }

                if (
                    error.response.data.errors
                    && Object.prototype.toString.call(error.response.data.errors) === '[object Object]'
                ) {
                    saveAuthResults({
                        authLoading: false,
                        displayAuthErrors: true,
                        authErrors: flatten(error.response.data.errors)
                    });
                    return false;
                }

                if (error.response.data.message) {
                    saveAuthResults({
                        authLoading: false,
                        displayAuthErrors: true,
                        authErrors: [error.response.data.message]
                    });
                    return false;
                }

                saveAuthResults({
                    authLoading: false,
                    displayAuthErrors: true,
                    authErrors: unknownError
                });
                return false;
            });
    }

    toggleErrorDisplay = () => {
        this.setState({displayAuthErrors: !this.state.displayAuthErrors});
    }

    setLoadingState = (loading: boolean) => {
        this.setState({
            authLoading: loading
        });
    }

    onChangeLanuguage = (language: string) => {
        this.setState({language: language});
        this.getAuthPageContent(language);
    }

    render() {
        const {
            jwt,
            sharedContentText,
            loadingContent,
            language,
            displayAuthErrors,
            authLoading,
            authErrors,
            siteKey
        } = this.state;
        let link: any;
        if (loadingContent) {
            return (<React.Fragment></React.Fragment>);
        }

        if (!loadingContent) {
            // Old CMS link, for reference
            // link = getLinkObj(sharedContentText.SharedContentItems, 'BottomFAQLink');

            link = 'https://dmv.vermont.gov/faq';
        }
        return (
            <div className="App">
                <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                    <Container fluid>
                    <Header
                        content={sharedContentText.SharedContentItems}
                        language={language}
                        onChangeLanguage={this.onChangeLanuguage}
                    />
                    <Router>
                        <Row>
                            <Col sm={2}></Col>
                            <Col>
                                <Switch>
                                    <Route exact path={["/", "/authenticate"]} render={() =>
                                        <AuthenticatePage
                                            onConfirm={this.authConfirm(this.setState.bind(this)).bind(this)}
                                            authText={this.state.authPageText.AuthPage}
                                            loading={authLoading}
                                            authErrors={authErrors}
                                            displayAuthErrors={displayAuthErrors}
                                            toggleAuthErrorDisplay={this.toggleErrorDisplay}
                                            setLoadingState={this.setLoadingState}
                                            language={language}
                                        />}
                                    />
                                </Switch>
                                {jwt
                                    ? <AuthenticatedApp
                                        authText={this.state.authPageText.AuthPage}
                                        authConfirm={this.authConfirm.bind(this)}
                                        jwt={jwt}
                                        language={language}
                                        reauthorized={this.state.reauthorized}
                                    />
                                    : <></>}
                                {!loadingContent &&
                                <div className="mt-4 auth-text-fields auth-text-greyed">
                                    <p>
                                        {getText(sharedContentText.SharedContentItems, 'BottomFAQMessage')}
                                        <a href={link}>{link}</a>
                                    </p>
                                </div>
                                }
                            </Col>
                            <Col className="no-padding" sm={2}></Col>
                        </Row>
                    </Router>
                    </Container>
                </GoogleReCaptchaProvider>
            </div>
        );
    }
}

export default App;
