import { getItemAction } from "./baseActions";

export const getEligibleCredentialsAction = () => {
    return getItemAction({
        url: '/cms/api/eligible-credentials',
    },
    (res: any) => {
        // removes the special mailing addresses from the credential
        // they are old and should not be carried forward
        const filteredData = res.data.map((credential: any) => {

            let filteredCredential = credential;
            const filteredAddresses = credential?.contextData?.addresses?.filter((address: any) => {
                return address?.addressType !== 3;
            });
            filteredCredential.contextData.addresses = filteredAddresses;

            return filteredCredential;
        });

        return filteredData;
    });
}
