import React from 'react';
import LogoutBtn from '../../components/LogoutBtn';
import { Row, Col, Button } from 'reactstrap';
import { getText } from '../../utils/ContentHelpers';
import SelectionTable from './components/SelectionTable';
import SelectedRecord from './components/SelectedRecord';
import { Redirect } from 'react-router-dom';
import { isEnhancedLogin } from '../../utils/UtilityFunctions';
import HelpModalWrapper from '../../components/HelpModalWrapper';
import SelectCredHelpModalBody from './components/SelectCredHelpModalbody';
import ErrorAlert from '../../components/ErrorAlert';

interface Props {
    content?: any
    credentials?: any
    language: string
    helpContent: any
    failedPayment: boolean
}

class HasCredentials extends React.Component <Props> {
    state = {
        redirectUrl: "",
        selectedCredential: {} as any,
        selectedRecordId: "",
        componentToRender: "credentialSelection",
        editCredentialText: {},
        replacementReasonList: {},
        eyeColorList: {},
        enhanced: false,
        addressContent: {},
        newServiceType: undefined,
        displayErrors: this.props.failedPayment || false
    };

    componentDidMount() {
        this.setEnhancedState();
    }
    setEnhancedState = () => {
        this.setState({enhanced: isEnhancedLogin()});
    }

    handleOnSelect = (recordId: any) => {
        const {credentials} = this.props;
        this.setState({
            selectedRecordId: recordId,
            selectedCredential: credentials.find((cred: any) => cred.id === recordId) || {}
        });
    }

    onClickRenew = () => {
        this.setState({
            componentToRender: 'editInformation',
            newServiceType: 'renew'
        });
    }

    onClickReplace = () => {
        this.setState({
            componentToRender: 'editInformation',
            newServiceType: 'replacement'
        });
    }

    editInformation = () => {
        return (
            <Redirect
                to={{
                    pathname: "/edit-information",
                    state: {
                        selectedCredential: this.state.selectedCredential,
                        newServiceType: this.state.newServiceType
                    }
                }}
            />
        );
    }

    toggleErrorDisplay = () => {
        this.setState({displayErrors: !this.state.displayErrors});
    }

    credentialSelection = () => {
        const {
            content,
            credentials,
            helpContent
        } = this.props;
        const {
            selectedRecordId,
            selectedCredential,
            enhanced,
            displayErrors
        } = this.state;
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h1 className="title">
                            {getText(content, 'MainTitle')}
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="selected-cred-label">
                            {getText(content, 'Subtitle')}
                        </h4>
                    </Col>
                </Row>
                <Row className="margin-bottom-20">
                    <Col>
                        <h4 className="section-heading">
                            {getText(content, 'AvailableCredHeading')}
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SelectionTable
                            data={credentials}
                            content={content}
                            onSelect={this.handleOnSelect}
                            selectedRowId={selectedRecordId}
                        />
                    </Col>
                </Row>
                {enhanced &&
                <Row>
                    <Col>
                        <h4 className="selected-cred-label">
                            {getText(content, 'EnhancedLogin')}
                        </h4>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <SelectedRecord
                            content={content}
                            credential={selectedCredential}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="section-heading">
                            {getText(content, 'ActionsHeading')}&nbsp;
                            <HelpModalWrapper headerTitle={helpContent}>
                                <SelectCredHelpModalBody content={helpContent} />
                            </HelpModalWrapper>
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Did not put in the CMS on purpose. */}
                        <ErrorAlert
                            errors={['We were unable to process your payment. '
                                + 'Please verify you entered the information correctly. '
                                + 'Contact your bank if this issue continues.']}
                            displayErrors={displayErrors}
                            toggleErrorDisplay={this.toggleErrorDisplay}
                        />
                    </Col>
                </Row>
                <Row className="select-cred-actions-group button-group">
                    <Col className="mb-3" sm={4}>
                        <div className="selected-cred-label">
                            {getText(content, 'CancelButtonMessage')}
                        </div>
                        <LogoutBtn
                            text={getText(content, 'CancelButtonText')}
                            className="cancel-btn"
                        />
                    </Col>
                    <Col className="mb-3" sm={4}>
                        <div className="selected-cred-label">View any previous transactions</div>
                        <Button
                            className="general-btn"
                            onClick={() => this.setState({ redirectUrl: '/transaction-history' })}
                        >
                            Transaction History
                        </Button>
                    </Col>
                    {/* FIXME This will not be correct most likely */}
                    {
                    selectedCredential
                    && (selectedCredential.renewal4YearPrice || selectedCredential.renewal2YearPrice || selectedCredential.restrictedPrice )
                    && <Col className="mb-3" sm={4}>
                            <div className="selected-cred-label">
                                {getText(content, 'RenewButtonMessage')}
                            </div>
                            <Button
                                className="general-btn"
                                onClick={this.onClickRenew}
                            >
                                {getText(content, 'RenewButtonText')}
                            </Button>
                        </Col>
                    }
                    {/* FIXME This will not be correct most likely */}
                    {selectedCredential && selectedCredential.replacementPrice &&
                        <Col className="mb-3" sm={4}>
                            <div className="selected-cred-label">
                                {getText(content, 'ReplaceButtonMessage')}
                            </div>
                            <Button
                                className="general-btn"
                                onClick={this.onClickReplace}
                            >
                                {getText(content, 'ReplaceButtonText')}
                            </Button>
                        </Col>
                    }
                </Row>
                {this.state.redirectUrl ? <Redirect to={this.state.redirectUrl}/> : <></>}
            </React.Fragment>
        );
    }
    render() {
        return this.state.componentToRender === 'credentialSelection'
            ? this.credentialSelection()
            : this.editInformation();
    }
}

export default HasCredentials;
