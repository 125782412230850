import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingIcon  = () => {
    return (
        <h3 className="loading-icon mt-5 text-center">
            Loading... <Spinner style={{ width: '3rem', height: '3rem' }} />
        </h3>
    );
}

export default LoadingIcon;
