import React from 'react';
import { getText } from '../../../utils/ContentHelpers';

interface Props {
    content: any
    ssn: string
    ddn: string
    pin: string
}

const InputInstructions = ({
    content,
    ssn,
    ddn,
    pin
}: Props) => {
    const displayInstructionSet1 = () => {
        if (!ssn && !pin && !ddn) {
            return (
                <p>
                    {getText(content, 'InstructionText1')}
                </p>
            );
        }
        return null;
    };

    const displayInstructionSet2 = () => {
        if ([ssn, pin, ddn].filter(el => !!el).length === 1) {
            return (
                <p>
                    {getText(content, 'InstructionText2')}
                </p>
            );
        }
        return null;
    }

    const displayInstructionSet3 = () => {
        if (
            [ssn, pin, ddn].filter(el => !!el).length === 2
            || (ssn && pin && ddn)
        ) {
            return (
                <p>
                    {getText(content, 'InstructionText3')}
                </p>
            );
        }
        return null;
    }
    return (
        <div className="mt-4 auth-text-fields auth-text-greyed">
            {displayInstructionSet1()}
            {displayInstructionSet2()}
            {displayInstructionSet3()}
        </div>
    );
};

export default InputInstructions;
