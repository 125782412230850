import React from 'react';
import {Row, Col,/* , Input, Label */
Label,
Input} from 'reactstrap';
import { getText } from '../../utils/ContentHelpers';
import TopHeader from './TopHeader';

interface Props {
    language: string
    content: any
    onChangeLanguage: (language: string) => void
}

class Header extends React.Component <Props> {

    onSelectChange = (event: any) => {
        sessionStorage.setItem('vor-localization', event.currentTarget.value);
        this.props.onChangeLanguage(event.currentTarget.value);
    }

    render() {
        const { content } = this.props;
        return (
            <header>
                <TopHeader />
                <Row className="secondary-header-bar">
                    <Col sm={2}></Col>
                    <Col className="second-header-text-container">
                        <Row className="header-agency-text mt-2">
                            <Col>
                                AGENCY OF TRANSPORTATION
                            </Col>
                        </Row>
                        <Row className="header-dept-text">
                            <Col>
                                <a href="https://dmv.vermont.gov/mydmv">
                                    Department of Motor Vehicles
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mt-4 auth-text-fields auth-text-greyed">
                                    <p>
                                        {getText(content, 'CustomHeaderMessage')}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <div className="language-picker-container" >
                            <Label for="language">{getText(content, 'LanguageLabel')}</Label>
                            <br />
                            <Input
                                className="language-picker"
                                type="select"
                                name="language"
                                onChange={this.onSelectChange}
                                value={this.props.language}
                            >
                                <option value="en-US">English (US)</option>
                                <option value="es-MX">Español</option>
                            </Input>
                        </div>
                    </Col>
                    <Col className="no-padding" sm={2}></Col>
                </Row>
            </header>
        );
    }
}

export default Header;
