import React from 'react';
import { getText, getLinkObj } from '../../../utils/ContentHelpers'
// import { HelpPopUpModal } from '../../../types';

interface Props {
    content: any
}

const SelectCredHelpModalBody = ({content}: Props) => {
    const link = getLinkObj(content, 'MoreHelpLink');
    return (
        <React.Fragment>
            <p>{getText(content, 'HelpInstructions')}</p>
            <h5>{getText(content, 'RenewalSectionHeader')}</h5>
            <div className="mb-3">
                <div>{getText(content, 'RenewalQuestion1')}</div>
                <div>{getText(content, 'RenewalAnswer1')}</div>
            </div>
            <div className="mb-3">
                <div>{getText(content, 'RenewalQuestion2')}</div>
                <div>{getText(content, 'RenewalAnswer2')}</div>
            </div>
            <h5>{getText(content, 'ReplaceSectionHeader')}</h5>
            <div className="mb-3">
                <div>{getText(content, 'ReplaceQuestion1')}</div>
                <div>{getText(content, 'ReplaceAnswer1')}</div>
            </div>
            <div className="mb-3">
                <div>{getText(content, 'ReplaceQuestion2')}</div>
                <div>{getText(content, 'ReplaceAnswer2')}</div>
            </div>
            <div>{getText(content, 'MoreHelpText')}</div>
            <div>{getText(content, 'MoreHelpText2')}<a href={link.Url}>{link.Text || link.Url}</a>{getText(content, 'MoreHelpText3')}</div>
        </React.Fragment>
    );
};

export default SelectCredHelpModalBody;
