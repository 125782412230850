import React from 'react';
import { Button } from 'reactstrap'

interface Props {
    text?: string
    className?: string
}

class LogoutBtn extends React.Component <Props> {

    onClick = () => {
        sessionStorage.clear();
        window.location.href = "/";
    }

    render() {
        const { className } = this.props;
        return (
            <Button
                className={className || "general-btn"}
                onClick={this.onClick}
            >
                {this.props.text || 'Done'}
            </Button>
        );
    }
}

export default LogoutBtn
