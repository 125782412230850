import React, { SyntheticEvent } from 'react';
import { Input, Button, Label, Row, Col, Card, FormFeedback, FormGroup } from 'reactstrap';
import { getText } from '../../utils/ContentHelpers';
import { isEnhancedLogin } from '../../utils/UtilityFunctions';
import { Addresses, Address, HelpPopUpModal, AuthFromValues } from '../../types';
import HelpModalWrapper from '../../components/HelpModalWrapper';
import HelpModalBody from '../AuthPage/components/HelpModalBody';
import ReAuthenticationModal from "../../components/ReAuthenticationModal";

export interface Props {
    authConfirm: (
        saveAuthResults: ({ authLoading, authErrors, displayAuthErrors }: { authLoading: boolean, displayAuthErrors: boolean, authErrors: any[] }) => void,
        reAuth?: boolean
    ) => (authFormValues: AuthFromValues, reCaptchaToken: string) => Promise<boolean>
    authText: any
    newAddresses: Addresses
    addressContent: any
    setNewAddressState: (addresses: Addresses) => any
    spMailingHelpModalContent: HelpPopUpModal
    fieldOptions: any
    isForeignAddress: boolean
    isAPOAddress: boolean
    isForeignAddressState: (value: boolean) => any
    isAPOAddressState: (value: boolean) => any
    language: string
    isCurrentPhysicalAddress: boolean | undefined
    isCurrentMailingAddress: boolean | undefined
    setIsCurrentPhysicalAddress: (current: boolean) => any
    setIsCurrentMailingAddress: (current: boolean) => any
    reauthorized?: boolean
}
class AddressManagement extends React.Component<Props> {
    state: any = {
        legalAddressInvalid: undefined,
        legalAddressError: undefined,
        otherAddressInvalid: undefined,
        otherAddressError: undefined,
        specialMailingAddressInvalid: undefined,
        specialMailingAddressError: undefined,
        specialMailingCityInvalid: undefined,
        specialMailingCityError: undefined,
        specialMailingZipInvalid: undefined,
        specialMailingZipError: undefined,
        specialMailingStateInvalid: undefined,
        specialMailingStateError: undefined,
        specialMailingCountryInvalid: undefined,
        specialMailingCountryError: undefined,
        cityInvalid: undefined,
        cityError: undefined,
        zipInvalid: undefined,
        zipError: undefined,
        stateInvalid: undefined,
        stateError: undefined,
        reAuthenticationModalOpen: false
    }

    legalStateList = [] as Array<string>;

    createSpecialMailing = () => {
        const newSpecialMailingAddress: Address = {
            addressType: 3,
            addressClass: 0,
            city: "",
            apocity: "",
            latestAddress: false,
            lineOne: "",
            lineTwo: "",
            lineThree: "",
            state: "",
            apostate: "",
            country: "",
            zip: "",
            addressChanged: true
        };
        let addresses = this.props.newAddresses;
        addresses.push(newSpecialMailingAddress);
        this.props.setNewAddressState(addresses);
    }

    removeSpecialMailing = () => {
        const { newAddresses } = this.props;
        const addressArray: Addresses = []
        newAddresses.forEach((addr) => {
            if (addr.addressType !== 3) {
                addressArray.push(addr);
            }
        });
        this.props.setNewAddressState(addressArray);
    }

    handleChange(index: number, dataType: string, value: string) {
        const newState = this.props.newAddresses.map((item, i) => {
            if (i === index) {
                // any change to address counts as correcting the address
                if (item.addressType === 0) {
                    this.props.setIsCurrentPhysicalAddress(true);
                }
                else if (item.addressType === 2) {
                    this.props.setIsCurrentMailingAddress(true);
                }
                return { ...item, [dataType]: value, addressChanged: true };
            }
            return item;
        });
        return this.props.setNewAddressState(newState);
    }

    checkForSpecialMailing = () => {
        return this.props.newAddresses.filter((address: any) => address.addressType === 3).length < 1;
    }

    verifyLegalAddressEvent = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.verifyLegalAddress(event.currentTarget.value);
    }

    verifyOtherAddressEvent = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.verifyOtherAddresses(event.currentTarget.value);
    }

    verifyCityEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifyCity(event.currentTarget.value, index)
    }

    verifyZipEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifyZip(event.currentTarget.value, index);
    }

    verifyStateEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifyState(event.currentTarget.value, index);
    }

    verifySpecialMailingAddressEvent = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.verifySpecialMailingAddress(event.currentTarget.value);
    }

    verifySpecialMailingCountryEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifySpecialMailingCountry(event.currentTarget.value, index)
    }

    verifySpecialMailingCityEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifySpecialMailingCity(event.currentTarget.value, index)
    }

    verifySpecialMailingZipEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifySpecialMailingZip(event.currentTarget.value, index);
    }

    verifySpecialMailingStateEvent = (event: React.SyntheticEvent<HTMLInputElement>, index: number) => {
        this.verifySpecialMailingState(event.currentTarget.value, index);
    }

    verifySpecialMailingAddress = (value: string) => {
        if (!value || value === '') {
            this.setState({
                specialMailingAddressError: getText(this.props.addressContent, 'StreetAddressRequired'),
                specialMailingAddressInvalid: true
            });
            return false;
        } else if (value.length > 35) {
            this.setState({
                specialMailingAddressError: getText(this.props.addressContent, 'StreetAddressToLong'),
                specialMailingAddressInvalid: true
            });
            return false;
        } else {
            this.setState({
                specialMailingAddressError: '',
                specialMailingAddressInvalid: undefined
            });
            return true;
        }
    }

    verifyLegalAddress = (value: string) => {
        const POBoxRegex = /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;

        if (!value || value === '') {
            this.setState({
                legalAddressError: getText(this.props.addressContent, 'StreetAddressRequired'),
                legalAddressInvalid: true
            });
            return false;
        } else if (POBoxRegex.test(value)) {
            this.setState({
                legalAddressError: getText(this.props.addressContent, 'NoLegalPOBox'),
                legalAddressInvalid: true
            });
            return false;
        } else if (value.length > 20) {
            this.setState({
                legalAddressError: getText(this.props.addressContent, 'StreetAddressToLong'),
                legalAddressInvalid: true
            });
            return false;
        } else {
            this.setState({
                legalAddressError: '',
                legalAddressInvalid: undefined
            });
            return true;
        }
    }

    verifyOtherAddresses = (value: string) => {
        if (!value || value === '') {
            this.setState({
                otherAddressError: getText(this.props.addressContent, 'StreetAddressRequired'),
                otherAddressInvalid: true
            });
            return false;
        } else if (value.length > 20) {
            this.setState({
                otherAddressError: getText(this.props.addressContent, 'StreetAddressToLong'),
                otherAddressInvalid: true
            });
            return false;
        } else {
            this.setState({
                otherAddressError: '',
                otherAddressInvalid: undefined
            });
            return true;
        }
    }

    verifyCity = (value: string, index: number) => {
        if (!value || value === '') {
            this.setState({
                ['cityError' + index]: getText(this.props.addressContent, 'CityRequired'),
                ['cityInvalid' + index]: true
            });
            return false;
        } else if (value.length > 16) {
            this.setState({
                ['cityError' + index]: getText(this.props.addressContent, 'CityToLong'),
                ['cityInvalid' + index]: true
            });
            return false;
        } else {
            this.setState({
                ['cityError' + index]: '',
                ['cityInvalid' + index]: undefined
            });
            return true;
        }
    }

    verifySpecialMailingCity = (value: string, index: number) => {
        if (!value || value === '') {
            this.setState({
                specialMailingCityError: getText(this.props.addressContent, 'CityRequired'),
                specialMailingCityInvalid: true
            });
            return false;
        } else if (value.length > 20) {
            this.setState({
                specialMailingCityError: getText(this.props.addressContent, 'CityToLong'),
                specialMailingCityInvalid: true
            });
            return false;
        } else {
            this.setState({
                specialMailingCityError: '',
                specialMailingCityInvalid: undefined
            });
            return true;
        }
    }

    verifySpecialMailingCountry = (value: string, index: number) => {
        if (!value || value === '') {
            this.setState({
                specialMailingCountryError: getText(this.props.addressContent, 'CountryRequired'),
                specialMailingCountryInvalid: true
            });
            return false;
        } else {
            this.setState({
                specialMailingCountryError: '',
                specialMailingCountryInvalid: undefined
            });
            return true;
        }
    }

    verifyZip = (value: string, index: number) => {
        if (!value || value === '') {
            this.setState({
                ['zipError' + index]: getText(this.props.addressContent, 'ZipRequired'),
                ['zipInvalid' + index]: true
            });
            return false;
        } else if (value.length > 11) {
            this.setState({
                ['zipError' + index]: getText(this.props.addressContent, 'ZipToLong'),
                ['zipInvalid' + index]: true
            });
            return false;
        } else {
            this.setState({
                ['zipError' + index]: '',
                ['zipInvalid' + index]: undefined
            });
            return true;
        }
    }

    verifySpecialMailingZip = (value: string, index: number) => {
        if (this.props.isForeignAddress) return true;

        if (!value || value === '') {
            this.setState({
                specialMailingZipError: getText(this.props.addressContent, 'ZipRequired'),
                specialMailingZipInvalid: true
            });
            return false;
        } else if (value.length > 11) {
            this.setState({
                specialMailingZipError: getText(this.props.addressContent, 'ZipToLong'),
                specialMailingZipInvalid: true
            });
            return false;
        } else {
            this.setState({
                specialMailingZipError: '',
                specialMailingZipInvalid: undefined
            });
            return true;
        }
    }

    verifyState = (value: string, index: number) => {
        if (!value || value === '') {
            this.setState({
                ['stateError' + index]: getText(this.props.addressContent, 'StateRequired'),
                ['stateInvalid' + index]: true
            });
            return false;
        } else {
            this.setState({
                ['stateError' + index]: '',
                ['stateInvalid' + index]: undefined
            });
            return true;
        }
    }

    verifySpecialMailingState = (value: string, index: number) => {
        if (!value || value === '') {
            this.setState({
                specialMailingStateError: getText(this.props.addressContent, 'StateRequired'),
                specialMailingStateInvalid: true
            });
            return false;
        } else {
            this.setState({
                specialMailingStateError: '',
                specialMailingStateInvalid: undefined
            });
            return true;
        }
    }

    displayFeedback = (invalid?: boolean) => {
        if (invalid) {
            return "true";
        }
        return undefined;
    }

    toggleForeignAddress = (index: number) => {
        if (this.props.isForeignAddress) {
            this.props.isForeignAddressState(false);
        } else {
            this.props.isForeignAddressState(true);
            this.props.isAPOAddressState(false);
            this.handleChange(index, 'state', '');
        }
    }

    toggleAPOAddress = (index: number) => {
        if (this.props.isAPOAddress) {
            this.props.isAPOAddressState(false);
        } else {
            this.props.isForeignAddressState(false);
            this.props.isAPOAddressState(true);
            this.handleChange(index, 'country', '');
            this.handleChange(index, 'city', '');
        }
    }

    apocityOptions = () => {
        return this.props.fieldOptions.apocity.map((city: any) => <option value={city.code}>{city.value}</option>);
    }

    apostateOptions = () => {
        return this.props.fieldOptions.apostate.map((state: any) => <option value={state.code}>{state.value}</option>);
    }

    stateOptions = () => {
        if (this.props.fieldOptions && this.props.fieldOptions.addrstate) {
            return this.props.fieldOptions.addrstate.map((state: any) => <option value={state.code}>{state.value}</option>);
        }
        return null;
    }

    legalStateOptions = () => {
        const stateOptions = [] as Array<any>;
        if (this.props.fieldOptions && this.props.fieldOptions.addrstate && this.props.newAddresses && this.props.newAddresses.constructor === Array) {
            const legalState = this.props.newAddresses.filter((address: any) => address.addressType === 0)[0].state;
            this.props.fieldOptions.addrstate.forEach((state: any) => {
                if (legalState === state.code || state.code === 'VT' || this.legalStateList.includes(state.code)) {
                    this.legalStateList.push(state.code);
                    stateOptions.push(<option value={state.code}>{state.value}</option>);
                }
            });
        };
        return stateOptions;
    }

    toggleReAuthenticationModal = (event?: SyntheticEvent) => {
        event?.preventDefault?.();
        this.setState({ reAuthenticationModalOpen: !this.state.reAuthenticationModalOpen });
    }

    handleCurrentPhysicalAddressChange = (event: any) => {

        const isCurrent = event.target.value === 'yes' ? true : false;
        this.props.setIsCurrentPhysicalAddress(isCurrent);
    }

    handleCurrentMailingAddressChange = (event: any) => {

        const isCurrent = event.target.value === 'yes' ? true : false;
        this.props.setIsCurrentMailingAddress(isCurrent);
    }
    
    buildCurrentAddress = (addressType: number) => {

        const handleCurrentAddressChange = addressType === 0 ?
            this.handleCurrentPhysicalAddressChange :
            this.handleCurrentMailingAddressChange;

        const isCurrent = addressType === 0 ?
            this.props.isCurrentPhysicalAddress :
            this.props.isCurrentMailingAddress;

        const notIsCurrent = isCurrent === undefined ? undefined : !isCurrent;

        const name = addressType === 0 ? 'physicalAddress' : 'mailingAddress';
        const idYes = name + 'Yes';
        const idNo = name + 'No';

        return (
            <div className="d-flex flex-column mb-3">
                <div className="d-flex">
                    <div>Is this your correct address?</div>
                    <div className="d-flex" onChange={handleCurrentAddressChange}>
                        <label htmlFor={idYes} className=" pl-2 pr-1" style={{ margin: '0' }}>Yes</label>
                        <input
                            type="radio"
                            id={idYes}
                            name={name}
                            value={"yes"}
                            checked={isCurrent}
                            style={{ height: '1.5em' }}
                        />
                        <label htmlFor={idNo} className="pl-2 pr-1" style={{ margin: '0' }}>No</label>
                        <input
                            type="radio"
                            id={idNo}
                            name={name}
                            value="no"
                            checked={notIsCurrent}
                            style={{ height: '1.5em' }}
                        />
                    </div>
                </div>
                {isCurrent !== undefined && !isCurrent &&
                    <div className="d-flex text-danger">* Please update this address</div>
                }
            </div>
        )
    }

    addressCards = () => {
        const addressDisplayNameMap: any = {
            0: getText(this.props.addressContent, 'LegalDisplayName'),
            1: getText(this.props.addressContent, 'CommercialDisplayName'),
            2: getText(this.props.addressContent, 'MailingDisplayName'),
            3: getText(this.props.addressContent, 'SpecialMailingDisplayName'),
            4: getText(this.props.addressContent, 'POBoxDisplayName')
        };

        if (this.props.newAddresses.length > 0) {
            return this.props.newAddresses.map((address: any, index: number) => {
                if (address.addressType !== 3) {
                    return (
                        <Card key={index}>
                            {isEnhancedLogin() || this.props.reauthorized
                                ? <div className="p-2">
                                    <Row>
                                        <Col className="text-center auth-text-greyed pt-2">
                                            <h4>
                                                {addressDisplayNameMap[address.addressType]}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right">
                                            {(address.addressType === 0 || address.addressType === 2) &&
                                                this.buildCurrentAddress(address.addressType)
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={2}>
                                            <Label>{getText(this.props.addressContent, 'Street')}</Label>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Input
                                                    onChange={(e) => this.handleChange(index, 'lineOne', e.target.value)}
                                                    value={this.props.newAddresses[index].lineOne}
                                                    required={true}
                                                    onBlur={address.addressType === 0 ? this.verifyLegalAddressEvent : this.verifyOtherAddressEvent}
                                                    invalid={address.addressType === 0 ? this.state.legalAddressInvalid : this.state.otherAddressInvalid}
                                                />
                                                <FormFeedback
                                                    invalid={this.displayFeedback(address.addressType === 0 ? this.state.legalAddressInvalid : this.state.otherAddressInvalid)}
                                                >
                                                    {address.addressType === 0 ? this.state.legalAddressError : this.state.otherAddressError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={2}>
                                            <Label>{getText(this.props.addressContent, 'City')}</Label>
                                        </Col>
                                        <Col sm={10}>
                                            <FormGroup>
                                                <Input
                                                    onChange={(e) => this.handleChange(index, 'city', e.target.value)}
                                                    value={this.props.newAddresses[index].city}
                                                    required={true}
                                                    onBlur={(e) => this.verifyCityEvent(e, index)}
                                                    invalid={this.state['cityInvalid' + index]}
                                                />
                                                <FormFeedback
                                                    invalid={this.displayFeedback(this.state['cityInvalid' + index])}
                                                >
                                                    {this.state['cityError' + index]}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={2}>
                                            <Label>{getText(this.props.addressContent, 'State')}</Label>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup>
                                                {address.addressType !== 2
                                                ? <Input
                                                    required={true}
                                                    onChange={(e) => this.handleChange(index, 'state', e.target.value)}
                                                    value={this.props.newAddresses[index].state}
                                                    type="select"
                                                    name="legalState"
                                                    onBlur={(e) => this.verifyStateEvent(e, index)}
                                                    invalid={this.state['stateInvalid' + index]}
                                                >
                                                    <option />
                                                    {this.props.fieldOptions ? this.legalStateOptions() : null}
                                                </Input>
                                                : <Input
                                                    required={true}
                                                    onChange={(e) => this.handleChange(index, 'state', e.target.value)}
                                                    value={this.props.newAddresses[index].state}
                                                    type="select"
                                                    name="mailingState"
                                                    onBlur={(e) => this.verifySpecialMailingStateEvent(e, index)}
                                                    invalid={this.state.specialMailingStateInvalid}
                                                >
                                                    <option />
                                                    {this.props.fieldOptions ? this.stateOptions() : null}
                                                </Input>}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <Label>{getText(this.props.addressContent, 'Zip')}</Label>
                                        </Col>
                                        <Col sm={4}>
                                            <FormGroup>
                                                <Input
                                                    className="width-80"
                                                    onChange={(e) => this.handleChange(index, 'zip', e.target.value)}
                                                    value={this.props.newAddresses[index].zip}
                                                    required={true}
                                                    onBlur={(e) => this.verifyZipEvent(e, index)}
                                                    invalid={this.state['zipInvalid' + index]}
                                                />
                                                <FormFeedback
                                                    invalid={this.displayFeedback(this.state['zipInvalid' + index])}
                                                >
                                                    {this.state['zipError' + index]}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div className="p-2 d-flex">
                                    <div className="auth-text-greyed pr-1">
                                        {addressDisplayNameMap[address.addressType] + ':  '}
                                    </div>
                                    <div className="pr-2 flex-grow-1">
                                        {address.lineOne + ' '}<br />
                                        {address.city + ', '}
                                        {address.state + ', '}
                                        {address.zip}
                                    </div>
                                    {(address.addressType === 0 || address.addressType === 2) &&
                                        this.buildCurrentAddress(address.addressType)
                                    }
                                </div>
                            }
                        </Card>
                    );
                } else {
                    return (
                        /* SPECIAL MAILING */
                        <Card key={index}>
                            <div className="p-2">
                                <Button onClick={this.removeSpecialMailing} size="sm">
                                    {getText(this.props.addressContent, 'RemoveSpecialMailingButton')}
                                </Button>&nbsp;
                                <HelpModalWrapper
                                    headerTitle={this.props.spMailingHelpModalContent}
                                >
                                    <HelpModalBody content={this.props.spMailingHelpModalContent} />
                                </HelpModalWrapper>
                                <Row>
                                    <Col className="text-center auth-text-greyed pt-2">
                                        <h4>
                                            {addressDisplayNameMap[address.addressType]}&nbsp;
                                        </h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row className="text-center">
                                            <Col>
                                                <Input
                                                    name="isForeignAddress"
                                                    type="checkbox"
                                                    checked={this.props.isForeignAddress}
                                                    onChange={() => this.toggleForeignAddress(index)}
                                                />
                                                {getText(this.props.addressContent, 'ForeignAddress')}
                                            </Col>
                                            <Col>
                                                <Input
                                                    name="isAPOAddress"
                                                    type="checkbox"
                                                    checked={this.props.isAPOAddress}
                                                    onChange={() => this.toggleAPOAddress(index)}
                                                />
                                                {getText(this.props.addressContent, 'APOFPO')}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={2}>
                                                <Label>{getText(this.props.addressContent, 'Street')}</Label>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Input
                                                        onChange={(e) => this.handleChange(index, 'lineOne', e.target.value)}
                                                        value={this.props.newAddresses[index].lineOne}
                                                        required={true}
                                                        onBlur={this.verifySpecialMailingAddressEvent}
                                                        invalid={this.state.specialMailingAddressInvalid}
                                                    />
                                                    <FormFeedback
                                                        invalid={this.displayFeedback(this.state.specialMailingAddressInvalid)}
                                                    >
                                                        {this.state.specialMailingAddressError}
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                        {this.props.isAPOAddress ?
                                            <React.Fragment>
                                                <FormGroup row>
                                                    <Label for="apocity" sm={2}>{getText(this.props.addressContent, 'POType')}</Label>
                                                    <Col>
                                                        <Input
                                                            required={true}
                                                            type="select"
                                                            name="apocity"
                                                            value={this.props.newAddresses[index].city}
                                                            onChange={(e) => this.handleChange(index, 'city', e.target.value)}
                                                        >
                                                            <option />
                                                            {this.apocityOptions()}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="apostate" sm={2}>
                                                        {getText(this.props.addressContent, 'State')}
                                                    </Label>
                                                    <Col>
                                                        <Input
                                                            required={true}
                                                            type="select"
                                                            name="apostate"
                                                            value={this.props.newAddresses[index].state}
                                                            onChange={(e) => this.handleChange(index, 'state', e.target.value)}
                                                        >
                                                            <option />
                                                            {this.apostateOptions()}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col sm={2}>
                                                        <Label>{getText(this.props.addressContent, 'Zip')}</Label>
                                                    </Col>
                                                    <Col sm={!this.props.isAPOAddress ? 4 : 10}>
                                                        <FormGroup>
                                                            <Input
                                                                onChange={(e) => this.handleChange(index, 'zip', e.target.value)}
                                                                value={this.props.newAddresses[index].zip}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </FormGroup>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <FormGroup row>
                                                    <Col sm={2}>
                                                        <Label>{getText(this.props.addressContent, 'City')}</Label>
                                                    </Col>
                                                    <Col sm={10}>
                                                        <FormGroup>
                                                            <Input
                                                                onChange={(e) => this.handleChange(index, 'city', e.target.value)}
                                                                value={this.props.newAddresses[index].city}
                                                                required={true}
                                                                onBlur={(e) => this.verifySpecialMailingCityEvent(e, index)}
                                                                invalid={this.state.specialMailingCityInvalid}
                                                            />
                                                            <FormFeedback
                                                                invalid={this.displayFeedback(this.state.specialMailingCityInvalid)}
                                                            >
                                                                {this.state.specialMailingCityError}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </FormGroup>
                                                <Row>
                                                    {!this.props.isForeignAddress
                                                    ? (
                                                        <React.Fragment>
                                                            <Col sm={2}>
                                                                <Label>{getText(this.props.addressContent, 'State')}</Label>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormGroup>
                                                                    <Input
                                                                        required={true}
                                                                        onChange={(e) => this.handleChange(index, 'state', e.target.value)}
                                                                        value={this.props.newAddresses[index].state}
                                                                        type="select"
                                                                        name="apocity"
                                                                        onBlur={(e) => this.verifySpecialMailingStateEvent(e, index)}
                                                                        invalid={this.state.specialMailingStateInvalid}
                                                                    >
                                                                        <option />
                                                                        {this.stateOptions()}
                                                                    </Input>
                                                                    <FormFeedback
                                                                        invalid={this.displayFeedback(this.state.specialMailingStateInvalid)}
                                                                    >
                                                                        {this.state.specialMailingStateError}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <Label>{getText(this.props.addressContent, 'Zip')}</Label>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormGroup>
                                                                    <Input
                                                                        onChange={(e) => this.handleChange(index, 'zip', e.target.value)}
                                                                        value={this.props.newAddresses[index].zip}
                                                                        required={true}
                                                                        onBlur={(e) => this.verifySpecialMailingZipEvent(e, index)}
                                                                        invalid={this.state.specialMailingZipInvalid}
                                                                    />
                                                                    <FormFeedback
                                                                        invalid={this.displayFeedback(this.state.specialMailingZipInvalid)}
                                                                    >
                                                                        {this.state.specialMailingZipInvalid}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <Col sm={2}>
                                                                <Label>{getText(this.props.addressContent, 'Country')}</Label>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormGroup>
                                                                    <Input
                                                                        onChange={(e) => this.handleChange(index, 'country', e.target.value)}
                                                                        value={this.props.newAddresses[index].country}
                                                                        required={true}
                                                                        onBlur={(e) => this.verifySpecialMailingCountryEvent(e, index)}
                                                                        invalid={this.state.specialMailingCountryInvalid}
                                                                    />
                                                                    <FormFeedback
                                                                        invalid={this.displayFeedback(this.state.specialMailingCountryInvalid)}
                                                                    >
                                                                        {this.state.specialMailingCountryError}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <Label>{getText(this.props.addressContent, 'Zip')}</Label>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormGroup>
                                                                    <Input
                                                                        onChange={(e) => this.handleChange(index, 'zip', e.target.value)}
                                                                        value={this.props.newAddresses[index].zip}
                                                                        required={false}
                                                                        onBlur={(e) => this.verifySpecialMailingZipEvent(e, index)}
                                                                        invalid={this.state.specialMailingZipInvalid}
                                                                    />
                                                                    {/* <FormFeedback
                                                                        invalid={this.displayFeedback(this.state.specialMailingZipInvalid)}
                                                                    >
                                                                        {this.state.specialMailingZipError}
                                                                    </FormFeedback> */}
                                                                </FormGroup>
                                                            </Col>
                                                        </React.Fragment>
                                                        )
                                                    }
                                                </Row>
                                            </React.Fragment>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                            )
                        }
                    });
                }
            }
    render() {
        return (
            <React.Fragment>
                {isEnhancedLogin()
                    ? <Card>
                        <div className="p-2 text-center">
                            <div className="auth-text-greyed">
                                {getText(this.props.addressContent, 'StreetSuffixInstructional')}
                            </div>
                            <a href={this.props.addressContent.StreetSuffix.Url} target="_blank" rel="noopener noreferrer">
                                {getText(this.props.addressContent, 'StreetSuffix')}
                            </a>
                        </div>
                    </Card>
                    : <Card>
                        <Row className="p-2 text-center">
                            <Col className="auth-text-greyed">
                                You will have to re-authenticate to allow address changes
                            </Col>
                            <Col className="d-flex align-items-center">
                                <Button
                                    onClick={this.toggleReAuthenticationModal}
                                    className="general-btn"
                                >
                                    Allow me to edit addresses
                                </Button>
                            </Col>
                        </Row>
                    </Card>}
                {this.addressCards()}
                {this.checkForSpecialMailing() && isEnhancedLogin()
                    ? (
                        <Card>
                            <div className="p-2">
                                <Button onClick={this.createSpecialMailing} size="sm">
                                    {getText(this.props.addressContent, 'SpecialMailingButton')}
                                </Button>&nbsp;
                                <HelpModalWrapper
                                    headerTitle={this.props.spMailingHelpModalContent}
                                >
                                    <HelpModalBody content={this.props.spMailingHelpModalContent} />
                                </HelpModalWrapper>
                            </div>
                        </Card>
                    )
                    : <React.Fragment />}
                <ReAuthenticationModal
                    authConfirm={this.props.authConfirm}
                    authText={this.props.authText}
                    isOpen={this.state.reAuthenticationModalOpen}
                    toggleModal={this.toggleReAuthenticationModal}
                    language={this.props.language}
                />
            </React.Fragment>
            );
        }
    }

export default AddressManagement;
