import {
    BaseItemActionObj,
    BaseGETListObj,
    BaseItemPOSTPUTActionObj
} from './types/actionTypes';
import axios, { AxiosResponse, AxiosError } from 'axios';

const buildSortString = (sortString?: string, pluralized: boolean = true): string => {
    if (sortString) {
        if (pluralized) {
            return `&sorts=${sortString}`;
        }
        return `&sort=${sortString}`;
    }
    return '';
};

const buildFilterString = (filterString?: string, pluralized: boolean = true): string => {
    if (filterString) {
        if (pluralized) {
            return `&filters=${filterString}`;
        }
        return `&filter=${filterString}`;
    }
    return '';
};

const buildRequestConfig = (): object => {
    const jwt = sessionStorage.getItem('vor-jwt');
    return {
        headers: {
            'authorization': 'Bearer ' + jwt,
            'Content-Type': 'application/json'
        }
    };
};

export const getListAction = (listReqObj: BaseGETListObj) => {
    const {
        count,
        offset,
        sortString,
        filterString,
        pluralizedFilter,
        pluralizedSort,
        customRequestConfig
    } = listReqObj;
    let { url } = listReqObj;

    url += `?offset=${offset}&count=${count}`;

    url += buildSortString(sortString, pluralizedSort);

    url += buildFilterString(filterString, pluralizedFilter);

    let requestConfigs = customRequestConfig;
    if (!requestConfigs) {
        requestConfigs = buildRequestConfig();
    }

    return axios.get(url, requestConfigs)
        .then((res: AxiosResponse) => {
            return {
                data: res.data
            };
        })
        .catch((err: AxiosError) => {
            throw err;
        });
};

export const getItemAction = (reqObj: BaseItemActionObj, resFilter?: (res: any) => any) => {
    const {
        url,
        customRequestConfig
    } =  reqObj;

    let requestConfigs = customRequestConfig;
    if (!requestConfigs) {
        requestConfigs = buildRequestConfig();
    }
    return axios.get(url, requestConfigs)
        .then((res: AxiosResponse) => {
            const filteredData = resFilter ? resFilter(res) : res.data;

            return {
                data: filteredData
            };
        })
        .catch((err: AxiosError) => {
            throw err;
        });
};

export const postItemAction = (reqObj: BaseItemPOSTPUTActionObj) => {
    const {
        url,
        customRequestConfig,
        bodyObj
    } =  reqObj;

    if (bodyObj !== undefined) {

        let requestConfigs = customRequestConfig;
        if (!requestConfigs) {
            requestConfigs = buildRequestConfig();
        }

        return axios.post(url, bodyObj, requestConfigs)
            .then((res: AxiosResponse) => {
                return {
                    data: res.data
                };
            })
            .catch((err: AxiosError) => {
                throw err;
            });
    }  else {
        throw new Error(`Missing bodyObj to preform POST to url: ${bodyObj}`);
    }
};

export const putItemAction = (reqObj: BaseItemPOSTPUTActionObj) => {
    const {
        url,
        customRequestConfig,
        bodyObj
    } = reqObj;

    let requestConfigs = customRequestConfig;
    if (!requestConfigs) {
        requestConfigs = buildRequestConfig();
    }

    if (bodyObj !== undefined) {
        return axios.put(url, bodyObj, requestConfigs)
            .then((res: any) => {
                return {
                    data: res.data
                };
            })
            .catch((err: any) => {
                throw err;
            });
    } else {
        throw new Error(`Missing bodyObj to preform PUT to url: ${bodyObj}`);
    }
};

export const deleteItemAction = (reqObj: BaseItemActionObj) => {
        const {
            url,
            customRequestConfig,
            id
        } = reqObj;

        let requestConfigs = customRequestConfig;
        if (!requestConfigs) {
            requestConfigs = buildRequestConfig();
        }

        return axios.delete(url, requestConfigs)
            .then((res: any) => {
                return {
                    data: res.data || id
                };
            })
            .catch((err: any) => {
                throw err;
            });
};
