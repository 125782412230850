import React from 'react';
import NoCredentials from '../workflows/CredentialSelection/NoCredentials';
import HasCredentials from '../workflows/CredentialSelection/HasCredentials';
import { getCredSelectionContent, getCredSelectionHelpContent } from '../requestActions/contentActions';
import { getEligibleCredentialsAction } from '../requestActions/credentialSelectionActions';
import LoadingIcon from '../components/LoadingIcon';

interface Props {
    language: string,
    queryParams: any
}

class CredentialSelection extends React.Component <Props> {

    state = {
        loading: true,
        credentials: null as any,
        credentialText: {} as any,
        helpContent: null as any,
        failedPayment: false
    }

    componentDidMount() {
        this.displayPaymentFailure();
        this.performAPICalls();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.queryParams !== this.props.queryParams ) {
            this.displayPaymentFailure();
        }
    }

    displayPaymentFailure = () => {
        if (
            this.props.queryParams
            && this.props.queryParams.has('result')
            && this.props.queryParams.get('result') === 'failure'
        ) {
            this.setState({failedPayment: true});
        }
    }

    performAPICalls = () => {
        const promiseArray = [
            getCredSelectionContent(this.props.language),
            getEligibleCredentialsAction()
        ]
        Promise.all(promiseArray)
            .then(([contentResp, credentialResp]: any) => {
                this.setState({
                    credentials: this.parseCredentialData(credentialResp.data),
                    credentialText: contentResp.data,
                    loading: false
                });

                return getCredSelectionHelpContent(this.props.language)
            })
            .then((helpResp) => {
                this.setState({helpContent: helpResp.data.RenewOrReplaceHelp});
            })
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = "/";
                }
            });
    }

    parseCredentialData = (data: any) => {
        return data.map((record: any) => {
            const rebuiltRecord = record;
            if (record && record.prices && record.prices.length) {
                record.prices.forEach((price: any) => {
                    if (price.name === 'Renewal - 4 year') {
                        rebuiltRecord.renewal4YearPrice = price.currency;
                    } else if (price.name === 'Renewal - 2 year') {
                        rebuiltRecord.renewal2YearPrice = price.currency;
                    } else if (price.name === 'Replacement') {
                        rebuiltRecord.replacementPrice = price.currency;
                    } else if (price.name === 'Restricted - 1 year') {
                        rebuiltRecord.restrictedPrice = price.currency;
                    }
                });
            }
            return rebuiltRecord;
        });
    }

    renderHasCredential = () => {
        const {
            credentials,
            credentialText,
            helpContent,
            failedPayment
         } = this.state;
        if (credentials.length) {
            return (
                <HasCredentials
                    credentials={credentials}
                    content={credentialText.CredentialSelection}
                    helpContent={helpContent}
                    language={this.props.language}
                    failedPayment={failedPayment}
                />
            );
        } else {
            return (
                <NoCredentials
                    content={credentialText.CredentialSelection}
                />
            );
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <LoadingIcon />
            );
        }
        return (
            <React.Fragment>
                {this.renderHasCredential()}
            </React.Fragment>
        );
    }
}

export default CredentialSelection;
