import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { getPaymentConfirmationContent, getSharedContent } from '../requestActions/contentActions';
import LoadingIcon from '../components/LoadingIcon';
import { getText, getLinkObj } from '../utils/ContentHelpers';
import {openPrintReceiptWindow, ReceiptModel} from '../utils/ReceiptGenerator';
import LogoutBtn from '../components/LogoutBtn';
import { completePaymentTransaction } from '../requestActions/transactionActions';
import { TransactionCompleteResponse } from '../types';

interface Props {
    language: string
}

class PrintReceipt extends React.Component <Props> {

    state = {
        loading: true,
        paymentConfirmationContent: {} as any,
        sharedContent: {} as any,
        receiptModel: {} as TransactionCompleteResponse,
        transactionParameters: {
            token: "",
            confId: "",
            result: ""
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        const transactionParameters = this.getTransactionParameters();
        if (transactionParameters) {
            this.setState({transactionParameters});
            if (transactionParameters.result === "success" || transactionParameters.result === "duplicate") {
                completePaymentTransaction(transactionParameters.token as string, transactionParameters.confId as string)
                    .then((res: any) => {
                        const receiptModel: TransactionCompleteResponse = res.data;
                        this.setState({receiptModel: receiptModel})
                    })
                    .then(() => {
                        const contentRequests = [
                            getPaymentConfirmationContent(this.props.language),
                            getSharedContent(this.props.language)
                        ];
                        return Promise.all(contentRequests)
                    })
                    .then(([paymentConfirmationContentResponse, sharedContentResponse]) => {
                        this.setState({
                            loading: false,
                            paymentConfirmationContent: paymentConfirmationContentResponse.data,
                            sharedContent: sharedContentResponse.data
                        });
                    })
                    .catch((err) => {
                        this.setState({loading: false});
                        console.error(err);
                    })
            } else {
                this.setState({loading: false});
            }
        }
        else {
            window.location.pathname = "";
        }
    }

    getTransactionParameters = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.has("result")) {
            return {
                result: params.get("result"),
                confId: params.get("confId"),
                token: params.get("token")
            };
        }
        return null;
    }

    openPrintReceiptTab = () => {
        const { receiptModel } = this.state;
        const printReceiptModel: ReceiptModel = {
            firstName: receiptModel.fields.firstname,
            lastName: receiptModel.fields.lastname,
            middleInitial: receiptModel.fields.middleinitial,
            suffix: receiptModel.fields.suffix,
            credentialType: receiptModel.fields.credentialtype,
            restrictions: receiptModel.fields.restrictions ? receiptModel.fields.restrictions : "N/A",
            endorsements: receiptModel.fields.endorsements ? receiptModel.fields.endorsements : "N/A",
            class: receiptModel.fields.class,
            organDonor: receiptModel.fields.organdonor ? "YES" : "NO",
            veteran: receiptModel.fields.veteran ? "YES" : "NO",
            paymentType: receiptModel.fields.paymenttype,
            lastFourCreditCard: receiptModel.fields.lastforcreditcard,
            cardholderName: receiptModel.fields.cardholdername,
            confirmationNumber: receiptModel.fields.confirmationnumber,
            orderTotal: receiptModel.fields.ordertotal,
            dateOfTransaction: new Date(receiptModel.fields.dateoftransaction).toLocaleString(),
            serviceType: receiptModel.fields.servicetype
        }

        openPrintReceiptWindow(printReceiptModel)
    }

    render() {
        const { paymentConfirmationContent, sharedContent, loading, receiptModel } = this.state;

        if (loading) {
            return <LoadingIcon />;
        }

        let duration = "";
        if (receiptModel.fields.servicetype === "Renew") {
            const pricingParts = receiptModel.fields.pricingname.split(" - ");
            duration = pricingParts[1];
        }

        const faqLink = getLinkObj(sharedContent.SharedContentItems, "BottomFAQLink");

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h1 className="title">{getText(paymentConfirmationContent.ConfirmationPage, "Title")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="selected-cred-label">{getText(paymentConfirmationContent.ConfirmationPage, "Subtitle")}</h4>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {
                            receiptModel.fields.servicetype === "Renew" &&
                            <Row className="mb-3">
                                <Col md={3} className="confirmation-label">
                                    {getText(paymentConfirmationContent.ConfirmationPage, "DurationLabel")}
                                </Col>
                                <Col className="confirmation-value">{duration}</Col>
                            </Row>
                        }
                        <Row className="mb-3">
                            <Col md={3} className="confirmation-label">
                                {getText(paymentConfirmationContent.ConfirmationPage, "CredTypeLabel")}
                            </Col>
                            <Col className="confirmation-value">{receiptModel.fields.credentialtype}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="confirmation-label">
                                {getText(paymentConfirmationContent.ConfirmationPage, "ServiceLabel")}
                            </Col>
                            <Col className="confirmation-value">{receiptModel.fields.servicetype}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="confirmation-label">
                                {getText(paymentConfirmationContent.ConfirmationPage, "ConfirmNumberLabel")}
                            </Col>
                            <Col className="confirmation-value">{receiptModel.fields.confirmationnumber}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Row>
                            <Col className="confirmation-text">
                                {getText(paymentConfirmationContent.ConfirmationPage, "TextBelowConfirmationFields")}{' '}
                                <a href={faqLink.Url}>{faqLink.Text || faqLink.Url}</a>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Row>
                                    <Col className="confirmation-text">
                                        {getText(paymentConfirmationContent.ConfirmationPage, "FinishButtonDescription")}
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm={6}>
                                        <LogoutBtn
                                            className="cancel-btn"
                                            text={getText(paymentConfirmationContent.ConfirmationPage, "FinishButtonText")}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="confirmation-text">
                                        {getText(paymentConfirmationContent.ConfirmationPage, "PrintButtonDescription")}
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col sm={6}>
                                        <Button
                                            onClick={this.openPrintReceiptTab}
                                            className="general-btn btn btn-secondary"
                                        >
                                            {getText(paymentConfirmationContent.ConfirmationPage, "PrintButtonText")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default PrintReceipt;
