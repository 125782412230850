import React from 'react';
import { Row, Col, Button, Card } from 'reactstrap';
import { getReviewEditsContent } from '../requestActions/contentActions';
import LoadingIcon from '../components/LoadingIcon';
import { getText } from '../utils/ContentHelpers';
import { parseCurrency, parseJWT, getCleanAddress } from '../utils/UtilityFunctions';
import { Addresses, TransactionModel, TransactionCreationResponse, Address } from '../types';
import { createPaymentTransaction } from '../requestActions/transactionActions';
import LaddaButton, {SLIDE_LEFT} from 'react-ladda';
import ErrorAlert from '../components/ErrorAlert';

interface Props {
    jwt: string
    selectedCredentialId: string
    language: string
    returnToEdit: () => void
    selectedCredential: any
    newServiceType?: string
    heightFeet?: string
    heightInches?: string
    weight?: string
    eyeColor?: string
    gender?: string
    organDonor?: boolean
    medicalDisclosure?: boolean
    declineVoter?: boolean
    cdlSelfCertificationType?: string
    citizen?: boolean
    legalPresence?: boolean
    resident?: boolean
    phone?: string
    email?: string
    receiveEmail?: boolean
    receiveSMS?: boolean
    newAddresses: Addresses
    price?: string,
    selectedPriceObj?: any
    eyeColorList: any
    genderList: any
    addressContent: any
    replacementReason?: string
    replacementReasonList: any
    keepSchoolBusEndorsement: any
    takenSchoolBusClinic: any
    keepHazardousMaterialsEndorsement: any
    submittedFingerprints: any
}

class Confirmation extends React.Component <Props> {

    state = {
        reviewEditsContent: {} as any,
        loading: false,
        submitting: false,
        displayAuthError: false,
        errorMessage: '',
        displayLogoutButton: false
    }

    componentDidMount() {
        this.setState({loading: true});
        getReviewEditsContent(this.props.language)
            .then((response: any) => this.setState({loading: false, reviewEditsContent: response.data }))
            .catch((error: any) => {
                this.setState({loading: false});
                console.error(error);
            });
    }

    displayMedicalAnswer = () => {
        const { medicalDisclosure } = this.props;
        const { reviewEditsContent } = this.state;
        if (medicalDisclosure) {
            return getText(reviewEditsContent.ReviewConfirmationPage, "YesAnswer");
        }
        return getText(reviewEditsContent.ReviewConfirmationPage, "NoAnswer");
    }

    displayVoterAnswer = () => {
        const { declineVoter, citizen, resident } = this.props;
        const { reviewEditsContent } = this.state;
        if (declineVoter) {
            return getText(reviewEditsContent.ReviewConfirmationPage, "DeclineAnswer");
        } else if (citizen && resident) {
            return getText(reviewEditsContent.ReviewConfirmationPage, "YesAnswer");
        } else {
            return getText(reviewEditsContent.ReviewConfirmationPage, "NoAnswer");
        }
    }

    displayOrganDonorAnswer = () => {
        const { organDonor } = this.props;
        const { reviewEditsContent } = this.state;
        if (organDonor) {
            return getText(reviewEditsContent.ReviewConfirmationPage, "YesAnswer");
        }
        return getText(reviewEditsContent.ReviewConfirmationPage, "NoAnswer");
    }

    displayEyeColor = () => {
        let displayColor = '';
        Object.keys(this.props.eyeColorList).forEach((color: string) => {
            if (this.props.eyeColorList[color] && this.props.eyeColorList[color].Text === this.props.eyeColor) {
                displayColor = color + ' (' + this.props.eyeColor + ')';
            }
        });
        return displayColor;
    }

    displayGender = () => {
        let displayGender = '';
        Object.keys(this.props.genderList).forEach((gender: string) => {
            if (this.props.genderList[gender] && this.props.genderList[gender].Text === this.props.gender) {
                displayGender = gender + ' (' + this.props.gender + ')';
            }
        });
        return displayGender;
    }

    displayAddresses = () => {
        const addressDisplayNameMap: any = {
            0: getText(this.props.addressContent, 'LegalDisplayName'),
            1: getText(this.props.addressContent, 'CommercialDisplayName'),
            2: getText(this.props.addressContent, 'MailingDisplayName'),
            3: getText(this.props.addressContent, 'SpecialMailingDisplayName'),
            4: getText(this.props.addressContent, 'POBoxDisplayName')
        };
        return this.props.newAddresses.map((address) => {
            if (address.addressType === 3) {
                const city = address.city ? `${address.city}, ` : '';
                const apocity = address.apocity ? `${address.apocity}, ` : '';
                const state = address.state ? `${address.state}` : '';
                const apostate = address.apostate ? `${address.apostate}` : '';
                const country = address.country ? `${address.country}` : '';
                const zip = address.zip ? `, ${address.zip}` : '';
                return (
                    <Card className="p-2">
                        <span><span className="address-label">{addressDisplayNameMap[address.addressType]} </span>
                        {`${address.lineOne}, ` + city + apocity + state + apostate + country + zip}
                        </span>
                    </Card>
                )
            }
            return (
                <Card className="p-2">
                    <span><span className="address-label">{addressDisplayNameMap[address.addressType]} </span>
                    {`${address.lineOne}, ${address.city}, ${address.state}, ${address.zip}`}
                    </span>
                </Card>
            );
        });
    }

    filterAddresses = () => {
        const addresses = this.props.newAddresses.filter((address: Address) => {
            return address.addressChanged
        }) || [];
        return addresses.map(x => getCleanAddress(x));
    }

    buildTransactionModel = (): TransactionModel => {
        const { jwt, selectedCredentialId, selectedPriceObj, newServiceType,
            heightFeet, heightInches, eyeColor, gender, weight, organDonor,
            email, phone, declineVoter, cdlSelfCertificationType, citizen,
            legalPresence, resident, medicalDisclosure, receiveEmail,
            replacementReason, receiveSMS, keepSchoolBusEndorsement, takenSchoolBusClinic,
            keepHazardousMaterialsEndorsement, submittedFingerprints} = this.props;

        var jwtPayload = parseJWT(jwt);

        var isVoter = !declineVoter && (citizen && resident);

        let model: TransactionModel = {
            identityId: jwtPayload.IdentityId,
            credentialId: selectedCredentialId,
            pricingName: selectedPriceObj.name || selectedPriceObj[0].name,
            serviceType: newServiceType as string,
            addressChanges: this.filterAddresses(),
            dataChanges: {
                heightfeet: heightFeet,
                heightinches: heightInches,
                eyecolor: eyeColor,
                gender: gender,
                weight: weight,
                organdonor: organDonor,
                cdlselfcertificationtype: cdlSelfCertificationType,
                uscitizen: citizen,
                legalproof: legalPresence,
                motorvoter: isVoter,
                medicalDisclosure: medicalDisclosure,
                email: email,
                telephone: phone,
                keepSchoolBusEndorsement: keepSchoolBusEndorsement,
                takenSchoolBusClinic: takenSchoolBusClinic,
                keepHazardousMaterialsEndorsement: keepHazardousMaterialsEndorsement,
                submittedFingerprints: submittedFingerprints
            },
            fields: {
                lang: this.props.language === 'es-MX' ? 'es' : '',
                email: email,
                telephone: phone,
                receiveEmail: receiveEmail,
                receiveSMS: receiveSMS
            }
        };

        if (newServiceType === "replacement") {
            model.dataChanges.replacementreason = replacementReason;
        }

        return model;
    }

    displayReplacementReason = () => {
        let display = '';
        Object.keys(this.props.replacementReasonList).forEach((reason: string) => {
            if (
                this.props.replacementReasonList[reason]
                && this.props.replacementReasonList[reason].Text === this.props.replacementReason
            ) {
                display = reason;
            }
        });
        return display;
    }

    displayDurationOrReason = () => {
        const { reviewEditsContent } = this.state;
        if (this.props.newServiceType ===  'renew') {
            return (
                <Row className="mb-3">
                    <Col md={3} className="brown-label">
                        {getText(reviewEditsContent.ReviewConfirmationPage, "DurationLabel")}
                    </Col>
                    <Col className="grey-label">{this.props.selectedPriceObj.name}</Col>
                </Row>
            );
        } else {
            return (
                <Row className="mb-3">
                    <Col md={3} className="brown-label">
                        {getText(reviewEditsContent.ReviewConfirmationPage, "ReplaceReasonLabel")}
                    </Col>
                    <Col className="grey-label">{this.displayReplacementReason()}</Col>
                </Row>
            );
        }
    }

    onConfirmClick = () => {
        // Here we build the transaction model and send it up to the api, get the redirect uri from the response if it is good and send them to the payment processor.
        const transactionModel = this.buildTransactionModel();

        this.setState({ submitting: true });
        createPaymentTransaction(transactionModel)
            .then((res) => {
                const transactionResponse: TransactionCreationResponse = res.data;
                window.location.href = transactionResponse.redirectUri;
                this.setState({ submitting: false })
            })
            .catch((err: any) => {
                let errorMessage = '';
                let displayLogoutButton;

                if (err.response?.status === 401) {
                    errorMessage = "Your session has expired. Please click the Return to Login button.";
                    displayLogoutButton = true;
                }
                else {
                    errorMessage = err.response?.data?.title;
                    displayLogoutButton = false;
                }

                this.setState({
                    submitting: false,
                    displayAuthError: true,
                    errorMessage: errorMessage,
                    displayLogoutButton: displayLogoutButton
                })
                console.error(err);
            });
    }
    toggleAuthErrorDisplay = () => {
        this.setState({displayAuthError: !this.state.displayAuthError})
    }

    render() {
        const {
            selectedCredential,
            selectedPriceObj,
            heightFeet,
            heightInches,
            weight
        } = this.props;
        const { loading, reviewEditsContent, displayAuthError } = this.state;

        if (loading) {
            return <LoadingIcon />;
        }

        return (
            <div className="edit-confirmation">
                <Row>
                    <Col>
                        <h1 className="title">{getText(reviewEditsContent.ReviewConfirmationPage, "Title")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="selected-cred-label">{getText(reviewEditsContent.ReviewConfirmationPage, "Subtitle")}</h4>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "LicenseTypeLabel")}
                            </Col>
                            <Col className="grey-label">{selectedCredential.contextData.licensetype}</Col>
                        </Row>
                        {this.displayDurationOrReason()}
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "HeightFeetLabel")}
                            </Col>
                            <Col className="grey-label">{heightFeet}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "HeightInchesLabel")}
                            </Col>
                            <Col className="grey-label">{heightInches}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "WeightLabel")}
                            </Col>
                            <Col className="grey-label">{weight}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "EyeLabel")}
                            </Col>
                            <Col className="grey-label">{this.displayEyeColor()}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "Gender")}
                            </Col>
                            <Col className="grey-label">{this.displayGender()}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "OrganDonorLabel")}
                            </Col>
                            <Col className="grey-label">
                                {this.displayOrganDonorAnswer()}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "MedicalQuestionLabel")}
                            </Col>
                            <Col className="grey-label">{this.displayMedicalAnswer()}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "VoterRegLabel")}
                            </Col>
                            <Col className="grey-label">{this.displayVoterAnswer()}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.displayAddresses()}
                    </Col>
                </Row>
                <Row className="mt-3 mb-3">
                    <Col>
                        <Row>
                            <Col md={3} className="brown-label">
                                {getText(reviewEditsContent.ReviewConfirmationPage, "PriceLabel")}
                            </Col>
                            <Col className="black-label">{parseCurrency(selectedPriceObj.currency)}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ErrorAlert
                            errors={[this.state.errorMessage]}
                            displayErrors={displayAuthError}
                            displayLogoutBtn={this.state.displayLogoutButton}
                            toggleErrorDisplay={this.toggleAuthErrorDisplay}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="bottom-text">
                        {getText(reviewEditsContent.ReviewConfirmationPage, "BottomText1")}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="bottom-text">
                        {getText(reviewEditsContent.ReviewConfirmationPage, "BottomText2")}
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="mt-3" md={4}>
                        <Button className="cancel-btn" onClick={this.props.returnToEdit}>
                            {getText(reviewEditsContent.ReviewConfirmationPage, "BackButtonText")}
                        </Button>
                    </Col>
                    <Col className="mt-3" md={4}>
                        <LaddaButton
                            loading={this.state.submitting}
                            data-style={SLIDE_LEFT}
                            className="btn general-btn"
                            onClick={this.onConfirmClick}
                        >
                                {getText(reviewEditsContent.ReviewConfirmationPage, "ConfirmButtonText")}
                        </LaddaButton>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Confirmation;
