import React, {useEffect, useState} from "react";
import ReactTable, {Column} from 'react-table';
import {Redirect} from "react-router-dom";
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import {Button, Col, Row} from "reactstrap";

import {RePrintReceiptButton} from "../../components/RePrintReceiptButton";
import {getPaymentTransactionHistory, TransactionHistoryItem} from "../../requestActions/transactionActions";
import LoadingIcon from '../../components/LoadingIcon';
import {parseCurrency} from "../../utils/UtilityFunctions";

const SelectTable = selectTableHOC(ReactTable);


export const TransactionHistory:React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [transactionHistory, setTransactionHistory] = useState<TransactionHistoryItem[]>([]);
    const [redirectUrl, setRedirectUrl] = useState<string>("");

    useEffect(() => {
        const isTransactionHistoryItemValid = (historyItem: TransactionHistoryItem): boolean => {
            if (!historyItem) return false;
            if (!historyItem.transactionId) return false;
            return true
        };

        setLoading(true);
        getPaymentTransactionHistory()
            .then(history => setTransactionHistory(history.data.filter(isTransactionHistoryItemValid)))
            .catch(error => console.error(error))
            .finally(() => setLoading(false));
    }, []);

    const formatDateCells = (row: any) => {
        if (row.value) {
            const dateString = row.value.split('T')[0];
            const dateArray = dateString.split("-");
            const formattedDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
            return (
                <div>
                    {formattedDate}
                </div>
            );
        }
        return (
            <div>
                {'N/A'}
            </div>
        );
    };

    const formatPriceCells = (row: any) => {

        if (row.value) {
            return (
                <div>
                    {parseCurrency(row.value, 'N/A')}
                </div>
            );
        }
        return (
            <div>
                {'N/A'}
            </div>
        );
    };

    const columns: Column[] = [
        {
            Header: "Credential Type",
            accessor: "fields.credentialtype"
        },
        {
            Header: "Service Type",
            accessor: "fields.servicetype"
        },
        {
            Header: "Transaction Date",
            accessor: "fields.dateoftransaction",
            Cell: formatDateCells
        },
        {
            Header: "Order Total",
            accessor: "fields.ordertotal",
            Cell: formatPriceCells
        },
        {
            Header: "Re-Print Receipt",
            accessor: "",
            Cell: (rowInfo) => <RePrintReceiptButton
                                    transaction={rowInfo?.original?.fields}
                                    dateBecameNotInflight={rowInfo?.original?.dateBecameNotInflight}
                                />
        }
    ];

    const renderTransactionTable = () => {
        if (loading) return <LoadingIcon />
        if (!transactionHistory.length) return (<p>No Transactions Found</p>)
        return (
            <SelectTable
                className="-striped"
                showPagination={false}
                columns={columns}
                data={transactionHistory}
                pageSize={transactionHistory.length}
                selectAll={false}
            />
        )
    };

    return (
        <>
            <Row>
                <Col>
                    <h1 className="title">
                        Transaction History
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className={!transactionHistory.length ? 'no-transactions' : ''}>
                    {renderTransactionTable()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="general-btn" onClick={() => setRedirectUrl("/select-credential")}>
                        Go Back to Select a Credential
                    </Button>
                </Col>
            </Row>
            {redirectUrl ? <Redirect to={redirectUrl} /> : <></>}
        </>
    )
}
