import { postItemAction, getItemAction } from './baseActions';

import { AuthRequestValues } from '../types';

export const getReCaptchaSiteKey = () => {
    return getItemAction({
        url: '/cms/recaptcha/site-key',
        customRequestConfig: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

export const authenticateAction = (bodyObj: AuthRequestValues) => {
    return postItemAction({
        url: '/cms/api/auth',
        bodyObj: bodyObj,
        customRequestConfig: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });
}
