import React from 'react';
import {
    Row,
    Col,
    Button,
    Input,
    Label,
    Form,
    FormFeedback,
    FormGroup
} from 'reactstrap';
import ReactLadda, {SLIDE_LEFT} from 'react-ladda';
import { AuthFromValues, AuthHelpBagContentItem } from '../types';
import { getText, getLinkObj } from '../utils/ContentHelpers';
import InputInstructions from '../workflows/AuthPage/components/InputInstructions';
import HelpModalWrapper from '../components/HelpModalWrapper';
import HelpModalBody from '../workflows/AuthPage/components/HelpModalBody';
// import HelpModal from '../workflows/AuthPage/components/HelpModal';
import ErrorAlert from '../components/ErrorAlert';
import {
    withGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import DatePicker from 'react-datepicker';
import { getAuthHelpContent } from '../requestActions/contentActions';
import { AuthHelpResponse } from '../types';

interface Props {
    authText?: any
    onConfirm: (formValues: AuthFromValues, reCaptchaToken: string) => void
    loading: boolean
    authErrors: Array<string>
    displayAuthErrors: boolean
    toggleAuthErrorDisplay: () => void
    googleReCaptchaProps?: any
    setLoadingState: (loading: boolean) => void
    language: string
}

interface State extends AuthFromValues {
    loading: boolean
    dob: Date | null
    ssnError: string
    lastNameError: string
    dobError: string
    idNumError: string
    ssnIsInvalid?: boolean
    lastNameInvalid?: boolean
    dobInvalid?: boolean
    idNumInvalid?: boolean
    requireOne?: boolean
    requireOneError: string
    PIDHelpContent: any
    PINHelpContent: any
    DDNHelpContent: any
}

class AuthenticatePage extends React.Component < Props, State > {
    state = {
        lastName: '',
        dob: null,
        idNum: '',
        ssn: '',
        pin: '',
        ddn: '',
        ssnError: '',
        lastNameError: '',
        dobError: '',
        idNumError: '',
        loading: false,
        lastNameInvalid: undefined,
        dobInvalid: undefined,
        idNumInvalid: undefined,
        ssnIsInvalid: undefined,
        requireOne: undefined,
        requireOneError: '',
        displayPIDHelp: false,
        displayPINHelp: false,
        displayDDNHelp: false,
        PIDHelpContent: null,
        PINHelpContent: null,
        DDNHelpContent: null
    };

    componentDidMount() {
        this.getHelpModalContent();
    }

    getHelpModalContent = () => {
        getAuthHelpContent(this.props.language)
        .then((res: AuthHelpResponse) => {
            if (res?.data?.BagPart?.ContentItems?.length) {
                this.parseAuthHelpContent(res.data.BagPart.ContentItems);
            }
        })
        .catch((error: any) => {
            console.error(error);
        });
    }

    parseAuthHelpContent = (contentArray: Array<AuthHelpBagContentItem>) => {
        type helpAliases = 'auth-content-help-pid'|'auth-content-help-pin'|'auth-content-help-ddn';
        type helpStateNames = 'PIDHelpContent'|'PINHelpContent'|'DDNHelpContent';

        const helpAliasToStateMap: Record<helpAliases, helpStateNames> = {
            'auth-content-help-pid': 'PIDHelpContent',
            'auth-content-help-pin': 'PINHelpContent',
            'auth-content-help-ddn': 'DDNHelpContent'
        } as const;
        const helpContent: Record<helpStateNames, any> = {
            PIDHelpContent: null,
            PINHelpContent: null,
            DDNHelpContent: null
        }
        contentArray.forEach((contentItem) => {
            const contentItemAlias = contentItem?.AliasPart?.Alias as helpAliases;
            if (helpAliasToStateMap[contentItemAlias] && contentItem.HelpPopupModal) {
                helpContent[helpAliasToStateMap[contentItemAlias]] = contentItem.HelpPopupModal;
            }
        });
        this.setState(helpContent);
    }

    onInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        this.setState(prevState => ({ ...prevState, [name]: value }));
    }

    verifySSN = (ssn?: string) => {
        // user hasn't entered SSN
        if (!ssn?.length) {
            this.setState(prevState => ({
                ...prevState,
                ssnError: '',
                ssnIsInvalid: false
            }));
            return true;
        }

        if (ssn.length < 9 || ssn.length > 9) {
            this.setState(prevState => ({
                ...prevState,
                ssnError: getText(this.props.authText, 'InvalidSSNLength'),
                ssnIsInvalid: true
            }));
            return false;
        }

        const ssnTest = RegExp(/^(\d{9})$/, 'g');
        if (!ssnTest.test(ssn)) {
            this.setState(prevState => ({
                ...prevState,
                ssnError: getText(this.props.authText, 'InvalidSSNFormat'),
                ssnIsInvalid: true
            }));
            return false;
        }

        // No errors found
        this.setState(prevState => ({
            ...prevState,
            ssnError: '',
            ssnIsInvalid: false
        }));
        return true;
    }

    verifyLastName = () => {
        const {lastName} = this.state;

        if (!lastName) {
            this.setState({
                lastNameError: getText(this.props.authText, 'LastNameRequired'),
                lastNameInvalid: true
            });
            return false;
        } else {
            this.setState({
                lastNameError: '',
                lastNameInvalid: undefined
            });
            return true;
        }
    }

    verifyDOB = () => {
        const {dob} = this.state;
        if (!dob) {
            this.setState({
                dobError: getText(this.props.authText, 'DOBRequired'),
                dobInvalid: true
            });
            return false;
        } else {
            if (!this.dobIsInPast(dob)) {
                this.setState({
                    dobError: getText(this.props.authText, 'DOBNotInFuture'),
                    dobInvalid: true
                });
                return false;
            } else {
                this.setState({
                    dobError: '',
                    dobInvalid: undefined
                });
                return true;
            }
        }
    }

    verifyIdNum = () => {
        const {idNum} = this.state;
        if (!idNum) {
            this.setState({
                idNumError: getText(this.props.authText, 'IDNumberRequired'),
                idNumInvalid: true
            });
            return false;
        } else {
            this.setState({
                idNumError: '',
                idNumInvalid: undefined
            });
            return true;
        }
    }

    dobIsInPast = (dob: Date | null) => {
        const dobTest = dob || new Date();
        const now = new Date();
        return dobTest.getTime() < now.getTime();
    }

    onSSNChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const ssn = value.replace(/[^\d]/g, '');
        this.verifySSN(ssn);
        this.setState({ssn: ssn});
    }

    onDOBChange = (date: Date) => {
        this.setState({dob: date});
    }

    onClickCancel = () => {
        this.setState({
            lastName: '',
            dob: null,
            idNum: '',
            ssn: '',
            pin: '',
            ddn: ''
        });
        const cancelBtnURL = getLinkObj(this.props.authText, 'CancelBtn').Url || '';
        if (cancelBtnURL) {
            sessionStorage.clear();
            window.location.href = cancelBtnURL;
        }
    }

    ssnPinOrDDNHasValue = () => {
        const {
            ssn,
            pin,
            ddn
        } = this.state;
        if (ssn || pin || ddn) {
            this.setState({
                requireOne: undefined,
                requireOneError: ''
            });
            return true;
        } else {
            this.setState({
                requireOne: true,
                requireOneError: getText(this.props.authText, 'OneIsRequired')
            });
            return false;
        }
    }

    onClickConfirm = (event: any) => {
        event.preventDefault();
        const lastNameIsValid = this.verifyLastName();
        const dobIsValid = this.verifyDOB();
        const idNumIsValid = this.verifyIdNum();
        const ssnPinOrDDNHasValue = this.ssnPinOrDDNHasValue();
        const ssnIsValid = this.verifySSN(this.state.ssn);
        if (
            lastNameIsValid
            && dobIsValid
            && idNumIsValid
            && ssnPinOrDDNHasValue
            && ssnIsValid
        ) {
            this.props.setLoadingState(true);
            this.props.googleReCaptchaProps.executeRecaptcha('authenticateUser')
                .then((token: string) => {
                    this.props.onConfirm(this.state, token);
                })
                .catch((error: any) => {
                    console.error(error);
                    this.props.setLoadingState(false);
                });
        }
    }

    displayFeedback = (invalid?: boolean) => {
        if (invalid) {
            return "true";
        }
        return undefined;
    }

    render() {
        const {
            lastName,
            dob,
            idNum,
            ssn,
            ssnIsInvalid,
            lastNameInvalid,
            dobInvalid,
            idNumInvalid,
            requireOne,
            requireOneError,
            ssnError,
            lastNameError,
            dobError,
            idNumError,
            pin,
            ddn,
            PIDHelpContent,
            PINHelpContent,
            DDNHelpContent
        } = this.state;
        const {
            authText,
            loading,
            authErrors,
            displayAuthErrors,
            toggleAuthErrorDisplay
        } = this.props;
        const ssnInvalidClass = ssnIsInvalid || requireOne ? 'is-invalid' : '';
        const dobInvalidClass = dobInvalid ? 'is-invalid' : '';
        const cancelBtnText = getLinkObj(authText, 'CancelBtn').Text || '';
        return (
            <Form onSubmit={this.onClickConfirm}>
                <Row>
                    <Col>
                        <h1 className="title">
                            {getText(authText, 'AuthTitle')}
                        </h1>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Row>
                            <Col>
                                <div className="auth-column-head">
                                    {getText(authText, 'LeftColHeading')}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="auth-text-greyed">
                                    {getText(authText, 'LeftColSubHeading')}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                {/* Trying to prevent the column from getting to small */}
                                <div className="auth-column-width"></div>
                                <Row className="mt-4">
                                    <Col>
                                        <FormGroup>
                                            <Label for="lastName">
                                                {getText(authText, 'LastNameLabel')}*
                                            </Label>
                                            <Input
                                                id="lastNameInput"
                                                name="lastName"
                                                onChange={this.onInputChange}
                                                required={true}
                                                value={lastName}
                                                invalid={lastNameInvalid}
                                                onBlur={this.verifyLastName}
                                            />
                                            <FormFeedback
                                                invalid={this.displayFeedback(lastNameInvalid)}
                                            >
                                                    {lastNameError}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <FormGroup>
                                        <Label for="dob">{getText(authText, 'DOBLabel')}*</Label>
                                        <br />
                                        <DatePicker
                                            id="dobInput"
                                            name="dob"
                                            className={`form-control ${dobInvalidClass}`}
                                            selected={dob}
                                            required={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                            placeholderText="MM/DD/YYYY"
                                            maxDate={new Date()}
                                            timeFormat="MM/DD/YYYY"
                                            onChange={this.onDOBChange}
                                            onBlur={this.verifyDOB}
                                        />
                                        <FormFeedback
                                            style={{display: 'block', margin: 0}}
                                            invalid={this.displayFeedback(dobInvalid)}
                                        >
                                            {dobError}
                                        </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <FormGroup>
                                        <Label for="idNum">
                                            {getText(authText, 'IDNumLabel')}*&nbsp;
                                            <HelpModalWrapper
                                                headerTitle={PIDHelpContent}
                                            >
                                                <HelpModalBody content={PIDHelpContent} />
                                            </HelpModalWrapper>
                                        </Label>
                                        <Input
                                            id="idNumInput"
                                            name="idNum"
                                            onChange={this.onInputChange}
                                            required={true}
                                            invalid={idNumInvalid}
                                            onBlur={this.verifyIdNum}
                                            value={idNum}
                                        />
                                        <FormFeedback
                                            invalid={this.displayFeedback(idNumInvalid)}
                                        >
                                            {idNumError}
                                        </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <div className="auth-column-head">
                                    {getText(authText, 'RightColHeading')}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="auth-text-greyed">
                                    {getText(authText, 'RightColSubHeading')}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <div className="auth-column-width"></div>
                                <Row className="mt-4">
                                    <Col>
                                        <FormGroup>
                                        <Label for="ssn">{getText(authText, 'SSNLabel')}</Label>
                                        <input
                                            name="ssn"
                                            type="text"
                                            inputMode="numeric"
                                            className={`form-control valid-password-field ${ssnInvalidClass}`}
                                            autoComplete="off"
                                            onChange={this.onSSNChange}
                                            onBlur={this.ssnPinOrDDNHasValue}
                                            value={ssn}
                                            maxLength={9}
                                            placeholder=""

                                            />
                                        <FormFeedback
                                            invalid={this.displayFeedback(ssnIsInvalid || requireOne)}
                                        >
                                            {ssnError || requireOneError}
                                        </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <FormGroup>
                                        <Label for="pin">
                                            {getText(authText, 'PINLabel')}&nbsp;
                                            <HelpModalWrapper
                                                headerTitle={PINHelpContent}
                                            >
                                                <HelpModalBody
                                                    content={PINHelpContent}
                                                />
                                            </HelpModalWrapper>
                                        </Label>
                                        <Input
                                            name="pin"
                                            onChange={this.onInputChange}
                                            invalid={requireOne}
                                            value={pin}
                                            onBlur={this.ssnPinOrDDNHasValue}
                                            />
                                        <FormFeedback
                                            invalid={this.displayFeedback(requireOne)}
                                        >
                                            {requireOneError}
                                        </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <FormGroup>
                                        <Label for="ddn">
                                            {getText(authText, 'DDNLabel')}&nbsp;
                                            <HelpModalWrapper
                                                headerTitle={PINHelpContent}
                                            >
                                                <HelpModalBody
                                                content={DDNHelpContent}
                                            />
                                            </HelpModalWrapper>
                                        </Label>
                                        <Input
                                            name="ddn"
                                            onChange={this.onInputChange}
                                            invalid={requireOne}
                                            value={ddn}
                                            onBlur={this.ssnPinOrDDNHasValue}
                                        />
                                        <FormFeedback
                                            invalid={this.displayFeedback(requireOne)}
                                        >
                                            {requireOneError}
                                        </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <ErrorAlert
                            errors={authErrors}
                            displayErrors={displayAuthErrors}
                            toggleErrorDisplay={toggleAuthErrorDisplay}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4">
                        <div className="auth-column-width"></div>
                        <Row>
                            <Col sm={8}>
                                <div className="auth-text-greyed">
                                    {getText(authText, 'CancelLabel')}
                                </div>
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col sm={8}>
                                <Button
                                    className="cancel-btn"
                                    type="button"
                                    onClick={this.onClickCancel}
                                >
                                    {cancelBtnText}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="mt-4">
                        <div className="auth-column-width"></div>
                        <Row>
                            <Col sm={8}>
                                <div className="auth-text-greyed">
                                    {getText(authText, 'ConfirmLabel')}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm={8}>
                                <ReactLadda
                                    type="submit"
                                    className="btn btn-secondary auth-buttons"
                                    data-style={SLIDE_LEFT}
                                    loading={loading}
                                >
                                    {getText(authText, 'ConfirmButtonText')}
                                </ReactLadda>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <InputInstructions
                    content={this.props.authText}
                    ssn={ssn}
                    pin={pin}
                    ddn={ddn}
                />
            </Form>
        );
    }
}

export default withGoogleReCaptcha(AuthenticatePage);
