import Handlebars from "handlebars";
import axios from "axios";

export type ReceiptModel = {
    firstName: string,
    lastName: string,
    middleInitial: string,
    suffix: string,
    credentialType: string,
    restrictions: string,
    endorsements: string,
    class: string,
    organDonor: string,
    veteran: string,
    paymentType: string,
    lastFourCreditCard: string,
    cardholderName: string
    confirmationNumber: string,
    dateOfTransaction: string,
    serviceType: string,
    orderTotal: number
}

const buildReceiptTemplate = () => {
    const templateUrl = '/templates/printReceipt.html';
    return axios.get(templateUrl)
        .then((res) => {
            const receiptTemplate = Handlebars.compile(res.data);
            return receiptTemplate;
        });
};

export const openPrintReceiptWindow = (model: ReceiptModel) => {
    const printReceiptWindow = window.open();
    if (printReceiptWindow) {
        buildReceiptTemplate()
            .then((template) => printReceiptWindow.document.write(template(model)));
    }
}
