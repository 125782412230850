import React from 'react';
import { Alert, Row, Col } from 'reactstrap';
import LogoutBtn from './LogoutBtn';

interface Props {
    errors: Array<string>
    displayErrors: boolean
    displayLogoutBtn?: boolean
    toggleErrorDisplay: () => void
}

const ErrorAlert = ({
    errors,
    displayErrors,
    displayLogoutBtn,
    toggleErrorDisplay
}: Props) => {

    const buildErrorList = () => {
        if (displayErrors) {
            if (errors.length === 1) {
                return (
                    <p className="auth-error-text">
                        <strong>Error: </strong>{errors[0]}
                    </p>
                );
            } else if (errors.length > 1) {
                const errorList = errors.map((errorString, index) => {
                    return (
                        <li key={`error-list-item-${index}`}>{errorString}</li>
                    );
                });
                return (
                    <React.Fragment>
                        <h5>Errors:</h5>
                        <ul>{errorList}</ul>
                    </React.Fragment>
                );
            }
        }
        return null;
    }
    const renderLogoutBtn = () => {
        if (displayLogoutBtn) {
            return (
                <Col sm={2}>
                    <LogoutBtn
                        className="error-logout-btn"
                        text="Return to Login"
                    />
                </Col>
            )
        }
    }

    return (
        <React.Fragment>
            <Alert color="danger" isOpen={displayErrors}  toggle={toggleErrorDisplay}>
                <Row>
                    <Col>
                        {buildErrorList()}
                    </Col>

                        {renderLogoutBtn()}

                </Row>
            </Alert>
        </React.Fragment>
    );
};

export default ErrorAlert;
