import React from 'react';

interface Props {
    onClick: () => void
}

const HelpIcon = ({onClick}: Props) => {
    return (
        <div className="help-icon"
            onClick={onClick}
        >
            ?
        </div>
    )
}

export default HelpIcon;
