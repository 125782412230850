import React from 'react';
import { getText } from '../../../utils/ContentHelpers'
import { HelpPopUpModal } from '../../../types';

interface Props {
    content: HelpPopUpModal | null
}

const HelpModalBody = ({content}: Props) => {
    const displayImg1 = () => {
        if (
            content
            && content.Image1
            && content.Image1.Paths
            && content.Image1.Paths[0]
        ) {
            return (
                <div className="help-image-container mb-2">
                    <img className="help-images" src={`/cms/media/${content.Image1.Paths[0]}`} title='help image 1' alt="help 1" />
                </div>
            );
        }
        return null;
    };
    const displayImg2 = () => {
        if (
            content
            && content.Image2
            && content.Image2.Paths
            && content.Image2.Paths[0]
        ) {
            return (
                <div className="help-image-container mb-2">
                    <img className="help-images" src={`/cms/media/${content.Image2.Paths[0]}`} title='help image 2' alt="help 2" />
                </div>
            );
        }
        return null;
    };
    return (
        <React.Fragment>
            <p>{getText(content, 'Instructions1')}</p>
            <p>{getText(content, 'Instructions2')}</p>
            {displayImg1()}
            {displayImg2()}
            <p>{getText(content, 'Instructions3')}</p>
        </React.Fragment>
    );
};

export default HelpModalBody;
