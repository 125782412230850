import React from 'react';
import {Row, Col} from 'reactstrap';

const TopHeader = () => {
    return (
        <Row className="top-header-bar">
            <Col sm={2}></Col>
            <Col
                className="official-site-col"
            >
                <span className="official-site-container">
                    <a href="https://www.vermont.gov">
                        Vermont Official State Website
                    </a>
                </span>
            </Col>
            <Col>
                <a
                    href="https://www.vermont.gov"
                >
                    <span className="logo-image">
                    </span>
                </a>
            </Col>
            <Col className="no-padding" sm={2}></Col>
        </Row>
    );
}

export default TopHeader;
