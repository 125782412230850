import React from 'react';
import { Row, Col } from 'reactstrap';
import { getText } from '../../../utils/ContentHelpers';

interface Props {
    content: any
    credential: any
}

const SelectedRecord = ({content, credential}: Props) => {

    // This may change and could be reusable
    const displayField = (property: string, notFoundReturn?: any) => {
        if (credential[property]) {
            return credential[property];
        }
        return notFoundReturn;
    };

    const displayContextField  = (property: string, notFoundReturn?: any) => {
        if (credential.contextData && credential.contextData[property]) {
            return credential.contextData[property];
        }
        return notFoundReturn;
    };

    const parseDateField = (dt: string) => {
        if (dt) {
            const dateString = dt.split('T')[0];
            const dateArray = dateString.split("-");
            const formattedDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
            return formattedDate;
        }
        return 'N/A';
    };

    return (
        <React.Fragment>
            <Row className="margin-bottom-20">
                <Col>
                    <h4 className="section-heading">
                        {getText(content, 'SelectionHeading')}
                    </h4>
                </Col>
            </Row>
            <Row className="margin-bottom-20">
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelLastName")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{displayContextField('lastname', 'N/A')}</strong>
                    </div>
                </Col>
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelMiddleName")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{displayContextField('middlename', 'N/A')}</strong>
                    </div>
                </Col>
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelFirstName")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{displayContextField('firstname', 'N/A')}</strong>
                    </div>
                </Col>
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelDOB")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{parseDateField(displayContextField('dateofbirth'))}</strong>
                    </div>
                </Col>
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelLicenseType")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{displayContextField('licensetype', 'N/A')}</strong>
                    </div>
                </Col>
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelIssueDate")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{parseDateField(displayField('issueDate'))}</strong>
                    </div>
                </Col>
                <Col className="mb-2" xs={12} sm={6} md={6} lg={6} xl={4}>
                    <div className="selected-cred-label">
                        {getText(content, "SelectedLabelExpDate")}
                    </div>
                    <div className="selected-cred-val">
                        <strong>{parseDateField(displayField('expirationDate'))}</strong>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SelectedRecord;
